// material-ui
import {useTheme,styled} from '@mui/material/styles';
import {
  Avatar,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography
} from '@mui/material';

import User1 from 'assets/images/users/user-round.svg';
import dayjs from 'dayjs';
import {useNavigate} from 'react-router';
var relativeTime=require('dayjs/plugin/relativeTime')

// styles
const ListItemWrapper=styled('div')(({theme}) => ({
  cursor: 'pointer',
  padding: 16,
  '&:hover': {
    background: theme.palette.primary.light
  },
  '& .MuiListItem-root': {
    padding: 0
  }
}));

// ==============================|| NOTIFICATION LIST ITEM ||============================== //

const NotificationList=({notifications,
  markAsViewed, 
  handleClose
}) => {
  const theme=useTheme();
  const navigate=useNavigate();
  dayjs.extend(relativeTime)

  if(!notifications) {
    return null;
  }

  const chipSX={
    height: 24,
    padding: '0 6px'
  };
  const chipErrorSX={
    ...chipSX,
    color: theme.palette.orange.dark,
    backgroundColor: theme.palette.orange.light,
    marginRight: '5px'
  };

  const chipWarningSX={
    ...chipSX,
    color: 'white',
    backgroundColor: '#ed8c3b'
  };

  const chipSuccessSX={
    ...chipSX,
    color: theme.palette.success.dark,
    backgroundColor: theme.palette.success.light,
    marginRight: '5px'
  };

  return (
    <List
      sx={{
        width: '100%',
        maxWidth: 330,
        py: 0,
        borderRadius: '10px',
        [theme.breakpoints.down('md')]: {
          maxWidth: 300
        },
        '& .MuiListItemSecondaryAction-root': {
          top: 22
        },
        '& .MuiDivider-root': {
          my: 0
        },
        '& .list-container': {
          pl: 7
        }
      }}
    >
      {
        notifications.sort((a) => a.visualizada === false ? -1 : 1).slice(0,4).map((item,index) => (
          <>
            <ListItemWrapper key={index}>
              <ListItem alignItems="center">
                <ListItemAvatar>
                  <Avatar alt={'Ruan Portella'} src={User1} />
                </ListItemAvatar>
                <ListItemText primary={item.deNome} />
                <ListItemSecondaryAction>
                  <Grid container justifyContent="flex-end">
                    <Grid item xs={12}>
                      <Typography variant="caption" display="block" gutterBottom>
                        {dayjs(item.date).locale('pt-br').fromNow()}
                      </Typography>
                    </Grid>
                  </Grid>
                </ListItemSecondaryAction>
              </ListItem>
              <Grid container direction="column" className="list-container">
                <Grid item xs={12} sx={{pb: 2}}>
                  <Typography variant="subtitle2">{item.titulo}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Grid container>
                    {
                      !item.visualizada&&(
                        <Grid item>
                          <Chip label="Não lida" sx={chipErrorSX} />
                        </Grid>
                      )
                    }
                    {
                      item.visualizada&&(
                        <Grid item>
                          <Chip label="Lida" sx={chipSuccessSX} />
                        </Grid>
                      )
                    }
                    {/* <Grid item>
                      <Chip label="Nova" sx={chipWarningSX} />
                    </Grid> */}
                    {
                      item.link&&(
                        <Grid item>
                          <Chip onClick={() => {
                            navigate(item.link)
                            markAsViewed([{id: item.id}])
                            handleClose()
                          }} label="Ver" sx={chipWarningSX} />
                        </Grid>
                      )
                    }
                  </Grid>
                </Grid>
              </Grid>
            </ListItemWrapper>
            <Divider />
          </>
        ))
      }

    </List>
  );
};

export default NotificationList;
