import {Box,IconButton,TextField,Typography} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {ImageToBlob} from "./ImageToBlob";
import fileDownload from "js-file-download";
import {IconDownload} from "@tabler/icons";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';
import Div from "ui-component/layouts/components/Div";

export default function InputsFake({type,value,label,multiline,rows,propState,setLoading, sx = {}}) {
    switch(type) {
        case 'text':
            return (
                <>
                    <TextField disabled fullWidth value={value} label={label} multiline={multiline} rows={rows} sx={sx} />
                    <Box sx={{minHeight: '25px'}}>
                    </Box>
                </>
            )
        case 'data':
            return (
                <>
                    <DatePicker disabled value={value} label={label} />
                    <Box sx={{minHeight: '25px'}}>
                    </Box>
                </>
            )
        case 'file-upload':
            return (
                <Box sx={{
                    border: '1px solid black',
                    padding: '20px',
                    textAlign: 'center',
                    borderRadius: '5px',
                    marginBottom: '20px',
                    backgroundColor: 'rgba(25, 36, 59, 0.08) !important',
                    position: 'relative',
                    width: '100%',
                }}>
                    <Typography variant='h8' color='textSecondary' className='upload-text' sx={{
                        position: 'absolute',
                        top: -10,
                        left: 10,
                        background: 'linear-gradient(180deg, white 50%, #E9E9E9 50%)',
                        padding: '0 5px',
                        fontSize: '11px'
                    }}>
                        {label}
                    </Typography>
                    {propState.map((arquivo) => (
                        <Box key={arquivo.id} sx={{my: '10px',display: 'flex',gap: 2,alignItems: 'center',zIndex: 999999,position: 'relative'}}>
                            <Box sx={{width: '100px',height: '60px',display: 'flex',justifyContent: 'center',alignItems: 'center',textAlign: 'center'}}>
                                <ImageToBlob arquivo={arquivo} field={{
                                    onChange: (value) => {
                                        propState=value;
                                    }
                                }} propState={propState} />
                            </Box>
                            <Typography variant="h5">{arquivo.titulo}</Typography>
                            <Box sx={{display: 'flex',alignItems: 'center',gap: 1,ml: 'auto'}}>
                                <IconButton size="small" onClick={async () => {
                                    try {
                                        setLoading(true);
                                        if(arquivo.created) {
                                            fileDownload(arquivo.file,arquivo.titulo);
                                        }
                                        else {
                                            const response=await axiosConfig.get(`/arquivos/arquivo/download/${arquivo.id}`,{
                                                responseType: 'blob'
                                            });
                                            fileDownload(response.data,arquivo.titulo);
                                        }
                                        dispatch(showSnackbar('Arquivo baixado com sucesso!','success'));
                                    } catch {
                                        dispatch(showSnackbar('Falha ao baixar arquivo!','error'));
                                    } finally {
                                        setLoading(false);
                                    }
                                }}>
                                    <IconDownload size={20} />
                                </IconButton>
                            </Box>
                        </Box>
                    ))}
                </Box>
            )
        case 'quill':
            return (
                <Div sx={{zIndex: '9999px'}}>
                    <ReactQuill
                        disabled
                        style={{
                            backgroundColor: 'rgba(25, 36, 59, 0.08) !important',
                        }}
                        modules={{
                            toolbar: [],
                            clipboard: {matchVisual: false}
                        }}
                        formats={[
                            'font','size',
                            'bold','italic','underline','strike',
                            'color','background',
                            'script',
                            'header','blockquote','code-block',
                            'indent','list',
                            'direction','align',
                            'link','image'
                        ]}
                        theme="snow"
                        value={value} />
                </Div>
            )
        case 'email':
            return <input type="email" />;
        default:
            return <input type="text" />;
    }
}