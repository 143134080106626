import React,{createContext,useState,useContext,useEffect} from 'react';
import {useConfigMenu} from './ConfigMenu';
import {io} from 'socket.io-client';
import axiosConfig from 'config/AxiosConfig';

const notificationsMock={
  'propostas': {link: (id) => `/comercial/propostas/visualizar/${id}`,name: (action) => action==='updated'? 'Uma proposta foi editada':'Uma nova proposta foi criada'},
}

const SocketConfigContext=createContext();

export const useSocketConfig=() => useContext(SocketConfigContext);

export const SocketConfigProvider=({children}) => {
  const [socketIo,setSocketIo]=useState(null);
  const [notifications,setNotifications]=useState([]);
  const [loading,setLoading]=useState(true);
  const {user,response}=useConfigMenu();
  const [emitAlert,setEmitAlert]=useState(false);

  const getNotifications=async () => {
    try {
      const response=await axiosConfig.get('/notificacoes/notificacoes/listar?whereR[logs_http][users_id][i]=1&limit=100');
      const notificationsResponse=response.data.data.objetos.map((notification) => {
        if(notificationsMock[notification.logs_http.tabela_afetada]) {
          const visualizada=notification.notificacoes_visualizada.map((item) => item.users_id).includes(user.id);
          return {
            id: notification.id,
            deNome: notification.logs_http.usuario_nome,
            visualizada: visualizada,
            titulo: `${notificationsMock[notification.logs_http.tabela_afetada].name(notification.logs_http.metodo==='PUT'? 'updated':'created')}`,
            date: notification.logs_http.metodo==='PUT'? notification.logs_http.updated_at:notification.logs_http.created_at,
            link: notificationsMock[notification.logs_http.tabela_afetada].link(notification.logs_http.id_afetado)
          };
        }
      });

      const data=notificationsResponse.filter((notification) => notification!==undefined);

      if(document.title.includes('(')) {
        document.title=document.title.split(')')[1];
      }
      if(data.filter((item) => item.visualizada===false).length>0) {
        document.title=`(${data.filter((item) => item.visualizada===false).length}) ${document.title}`;
      }

      setNotifications(data);
    } catch(error) {
      console.error(error);
    }
  };

  const getNotificationById=async (id) => {
    try {
      const response=await axiosConfig.get(`/notificacoes/notificacoes/listar?where[logs_http_id]=${id}`);

      return response.data.data.objetos[0].id;
    } catch(error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if(user) {

      (async () => {
        if (user.id === 1) {
          await getNotifications();
        }
      })();

      let query={};

      query['userId']=user.id;
      query['urlTratada']=response?.config?.urlTratada;

      if(response?.config?.nodeJsSalas) {
        query['salas']=response.config.nodeJsSalas;
      }

      if(user?.nodeJsSalas) {
        query['salasUsuarios']=user.nodeJsSalas;
      }

      // const linkNodeJs=`${process.env.REACT_APP_SOCKET_URL}`||`${window.location.protocol}//${response?.config?.nodeJs}`;
      const linkNodeJs='https://devnojsinterna.resoluti.com.br:3001';
      const socket=io(linkNodeJs,{
        transports: ['websocket','polling','flashsocket'],
        reconnection: false,
        query
      });

      socket.on('disconnect',function() {
        socket.io.reconnect();
      })

      socket.on('connect',function() {
      })

      // Onde recebo os sockets gerais

      // socket.on(response?.config?.urlTratada,function(data) {
      //   console.log(data);
      // });

      // Onde recebo os sockets do usuario logado

      // socket.on(`${response?.config?.urlTratada}_${user.id}`,function(data) {
      //   console.log(data);
      // });

      // sala asterisk

      // if(response?.config?.asterisk) {
      //   socket.on(`${response.config.urlTratada}_asterisk`,function(data) {
      //     console.log(data);
      //   });
      // }

      // socket.on('notificacoesGerais',function(data) {
      //   console.log(data);
      // });

      // socket.emit('register',user.id);

      // socket.on(
      //   // 'mensagem_usuario',
      //   'get_notifications',
      //   (notification) => {
      //     setNotifications((prevNotifications) => {
      //       const updatedNotifications=[notification,...prevNotifications];
      //       return updatedNotifications;
      //     });
      //     console.log('Todos notificações:',notification);
      //   });

      socket.on('alert',async (notification) => {
        let notificationsState=[]

        if (user.id === 1) {
          if(notification.name==='log') {
            if(notification?.action) {
              const notificationBody=notification.evt;
  
  
              if(notificationsMock[notificationBody.tabela_afetada]) {
                const id=await getNotificationById(notificationBody.id);
                setNotifications((prevNotifications) => {
                  const updatedNotifications=[{
                    id: id,
                    deNome: notificationBody.usuario_nome,
                    visualizada: false,
                    titulo: `${notificationsMock[notificationBody.tabela_afetada].name(notification.action)}`,
                    date: notification.action==='updated'? notificationBody.updated_at:notificationBody.created_at,
                    link: notificationsMock[notificationBody.tabela_afetada].link(notificationBody.id_afetado)
                  },
                  ...prevNotifications
                  ];
                  notificationsState=updatedNotifications;
                  return updatedNotifications;
                });
  
                if(document.title.includes('(')) {
                  document.title=document.title.split(')')[1];
                }
                if(notificationsState.filter((item) => item.visualizada===false).length>0) {
                  document.title=`(${notificationsState.filter((item) => item.visualizada===false).length}) ${document.title}`;
                }
                setEmitAlert(true);
                setTimeout(() => {
                  setEmitAlert(false);
                },4000);
              }
            }
          }
        }

        // localStorage.setItem('notificacoes',JSON.stringify(notificationsState));
      });

      setSocketIo(socket);

      return () => {
        socket.disconnect();
      };
    }
  },[user])


  const markAsViewed=async (notificationsIds) => {
    try {
      setLoading(true);
      const body=notificationsIds.map((not) => {
        return {notificacoes_id: not.id,users_id: user.id};
      });

      const updatedNotifications=notifications.map((notification) => {
        if(notificationsIds.filter((item) => item.id===notification.id).length>0) {
          return {...notification,visualizada: true};
        }

        return notification;
      });

      setNotifications(updatedNotifications);

      if(body.length>0) {
        await axiosConfig.post('/notificacoes/visualizada/cadastrar',{data: body});
      }

      if(document.title.includes('(')) {
        document.title=document.title.split(')')[1];
      }

      if(updatedNotifications.filter((item) => item.visualizada===false).length>0) {
        document.title=`(${updatedNotifications.filter((item) => item.visualizada===false).length}) ${document.title}`;
      }
    } catch(error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SocketConfigContext.Provider value={{socketIo,notifications,markAsViewed,emitAlert,loading}}>
      {children}
    </SocketConfigContext.Provider>
  );
};
