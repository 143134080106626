import {Add,ChevronLeft,MoreHoriz,Remove} from "@mui/icons-material";
import {
    Autocomplete,
    Avatar,Box,Card,CardContent,Grid,IconButton,Modal,Paper,Skeleton,
    // SpeedDial,SpeedDialAction,SpeedDialIcon,
    Table,TableBody,TableCell,TableContainer,TableHead,TableRow,TextField,Tooltip,Typography,
    useMediaQuery,
    useTheme
} from "@mui/material";
import Div from "ui-component/layouts/components/Div";
import Chart from "react-apexcharts";
import CardAnalytics from "./components/CardAnalytics";
import {MapContainer,Marker,Popup,TileLayer} from "react-leaflet";
import {useCallback,useState} from "react";
import CardGraphPie from "./components/CardGraphPie";
import CardOkr from "./components/CardOkr";
import {useEffect} from "react";
import axiosConfig from "config/AxiosConfig";
// import {DatePickerRange} from "components/DatePickerRange";
import {useLocation,useNavigate} from "react-router";
import qs from 'query-string';
import {addDays,format,subDays} from "date-fns";
import {formatterMoney} from "utils/formatterMoney";
import dayjs from "dayjs";
import Carousel from "react-material-ui-carousel";
import ListarModal from "./components/ListarModal";

// quero a lat e lng de cada estado do brasil

const mapsLatLng={
    'Acre': {
        lat: -9.047867,
        lng: -70.526497,
    },
    'Alagoas': {
        lat: -9.571297,
        lng: -36.781950,
    },
    'Amapá': {
        lat: 0.034457,
        lng: -51.066513,
    },
    'Amazonas': {
        lat: -3.416842,
        lng: -65.856064,
    },
    'Bahia': {
        lat: -12.285251,
        lng: -41.929477,
    },
    'Ceará': {
        lat: -5.498398,
        lng: -39.320886,
    },
    'Distrito Federal': {
        lat: -15.775125,
        lng: -47.797424,
    },
    'Espírito Santo': {
        lat: -19.568768,
        lng: -40.172060,
    },
    'Goiás': {
        lat: -15.827037,
        lng: -49.296884,
    },
    'Maranhão': {
        lat: -5.070866,
        lng: -45.407452,
    },
    'Mato Grosso': {
        lat: -12.681871,
        lng: -56.921099,
    },
    'Mato Grosso do Sul': {
        lat: -20.772661,
        lng: -54.785272,
    },
    'Minas Gerais': {
        lat: -18.512178,
        lng: -44.555031,
    },
    'Pará': {
        lat: -3.276027,
        lng: -52.315691,
    },
    'Paraíba': {
        lat: -7.121936,
        lng: -36.724684,
    },
    'Paraná': {
        lat: -24.484430,
        lng: -51.814879,
    },
    'Pernambuco': {
        lat: -8.411631,
        lng: -37.591698,
    },
    'Piauí': {
        lat: -7.718296,
        lng: -42.728418,
    },
    'Rio de Janeiro': {
        lat: -22.275276,
        lng: -42.419415,
    },
    'Rio Grande do Norte': {
        lat: -5.402599,
        lng: -36.954107,
    },
    'Rio Grande do Sul': {
        lat: -30.109628,
        lng: -51.317437,
    },
    'Rondônia': {
        lat: -10.943509,
        lng: -62.827385,
    },
    'Roraima': {
        lat: 1.888229,
        lng: -61.194264,
    },
    'Santa Catarina': {
        lat: -27.062836,
        lng: -51.114965,
    },
    'São Paulo': {
        lat: -22.019014,
        lng: -47.951442,
    },
    'Sergipe': {
        lat: -10.947247,
        lng: -37.073076,
    },
    'Tocantins': {
        lat: -10.885512,
        lng: -48.371691,
    },
}

export default function Dashboard() {
    const [width_1,setWidth_1]=useState(4)
    const [width_2,setWidth_2]=useState(4)
    const [data,setData]=useState([])
    const [optionsTemplate,setOptionsTemplate]=useState([])
    const params=qs.parse(useLocation().search);
    const router=useNavigate();
    const pathname=useLocation().pathname;
    const [loading,setLoading]=useState(true);
    const [selected,setSelected]=useState(undefined);
    const [openResults_1,setOpenResults_1]=useState(false);
    const [openResults_2,setOpenResults_2]=useState(false);
    const [assunto,setAssunto]=useState('');
    const theme=useTheme();
    const isMobile=useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet=useMediaQuery(theme.breakpoints.down('md'));
    const chunkSize=isTablet? (isMobile? 1:2):3;

    const from=params.from
    const to=params.to

    const defaultTo=new Date('2024-12-31');
    const defaultFrom=subDays(defaultTo,30);

    const dateState={
        from: from? addDays(new Date(from),1):defaultFrom,
        to: to? addDays(new Date(to),1):defaultTo
    }

    useEffect(() => {
        const fetchTemplates=async () => {
            try {
                const response=await axiosConfig.get(`/pinepr/templatestextos/listar?where[contratos_id]=2`);
                const data=response.data.data.objetos;

                const options=data.map((item) => ({
                    value: item.id,
                    label: dayjs(item.from).format('DD/MM/YYYY')+' - '+dayjs(item.to).format('DD/MM/YYYY'),
                    from: item.from,
                    to: item.to,
                    textos: {
                        ...item
                    }
                }));

                const query={
                    from: format(options?.[0]?.from||defaultFrom,'yyyy-MM-dd'),
                    to: format(options?.[0]?.to||defaultTo,'yyyy-MM-dd'),
                };

                const url=qs.stringifyUrl({
                    url: pathname,
                    query
                },{skipNull: true,skipEmptyString: true});

                router(url);

                setSelected(options?.[0]);
                setOptionsTemplate(options);
                fetchData(options);
            } catch(error) {
                console.log(error)
            }
        }

        fetchTemplates();
    },[])

    const fetchData=useCallback(async (options) => {
        try {
            setLoading(true);

            if(optionsTemplate.length===0&&!options) return;

            let optionsTemplateTemp=options||optionsTemplate;

            const existOptionWithFromAndTo=optionsTemplateTemp.find((item) => {
                return format(item.from,'yyyy-MM-dd')===format(dateState.from,'yyyy-MM-dd')&&format(item.to,'yyyy-MM-dd')===format(dateState.to,'yyyy-MM-dd');
            });

            if(!existOptionWithFromAndTo) {
                const query={
                    from: format(optionsTemplateTemp?.[0]?.from||defaultFrom,'yyyy-MM-dd'),
                    to: format(optionsTemplateTemp?.[0]?.to||defaultTo,'yyyy-MM-dd'),
                };

                const url=qs.stringifyUrl({
                    url: pathname,
                    query
                },{skipNull: true,skipEmptyString: true});

                router(url);
            } else {
                const response=await axiosConfig.get(`${pathname}?where[contratos_id]=2&from=${format(dateState.from,'yyyy-MM-dd')}&to=${format(dateState.to,'yyyy-MM-dd')}`);

                const objData={
                    ...response.data.data.objetos[0],
                    posts: response?.data?.data?.objetos[0]?.posts?.slice(0,3),
                }

                setData(objData)
            }

        } catch(error) {
            console.log(error)
            setData(
                {
                    "graficos": {
                        "sentiment": {
                            "labels": [
                                "Positivo",
                                "Neutro",
                                "Negativo"
                            ],
                            "series": [
                                1,
                                1,
                                1
                            ]
                        },
                        "tiers": {
                            "labels": [
                                3,
                                2,
                                1
                            ],
                            "series": [
                                1,
                                1,
                                1
                            ]
                        },
                        "visual_impact": {
                            "labels": [
                                "FOTO",
                                "LOGO"
                            ],
                            "series": [
                                1,
                                1
                            ]
                        },
                        "origem": {
                            "labels": [
                                "Valor Econômico",
                                "O Globo",
                                "Folha de S. Paulo",
                                "Correio Braziliense",
                                "Outros"
                            ],
                            "series": [
                                1,
                                1,
                                1,
                                1,
                                1
                            ]
                        },
                        "tipo": {
                            "labels": [
                                "Nenhum veiculo encontrado",
                                "Online",
                                "Impresso"
                            ],
                            "series": [
                                1,
                                1,
                                1
                            ]
                        },
                        "tema": {
                            "labels": [
                                "Vazio",
                                "Compensação Fiscal",
                                "Liderança Feminina",
                                "Gestão de Precatórios - CBP",
                                "Outros"
                            ],
                            "series": [
                                1,
                                1,
                                1,
                                1,
                                1
                            ]
                        },
                        "frequencias": [
                            {
                                "date": "2024-12-18",
                                "value": 1,
                            },
                        ],
                    },
                    "sentiment_positive": {
                        "value": 0,
                        "percent": 0
                    },
                    "media_outlet": {
                        "value": 0,
                        "percent": 0
                    },
                    "ave": {
                        "value": 0,
                        "percent": 0
                    },
                    "absolute_reach": {
                        "value": 0,
                        "percent": 0
                    },
                    "tiers": {
                        "1": {
                            "value": 0,
                            "percent": 0
                        },
                        "2": {
                            "value": 0,
                            "percent": 0
                        },
                        "3": {
                            "value": 0,
                            "percent": 0
                        }
                    },
                    "visual_impact": {
                        "value": 0,
                        "percent": 0
                    },
                    "porta_voz": [
                        {
                            "nome": "PinePr",
                            "resultado": 0,
                            "resultado_texto": 0,
                            "imagem": ""
                        },
                        {
                            "nome": "PinePr",
                            "resultado": 0,
                            "resultado_texto": 0,
                            "imagem": ""
                        },
                        {
                            "nome": "PinePr",
                            "resultado": 0,
                            "resultado_texto": 0,
                            "imagem": ""
                        },
                        {
                            "nome": "PinePr",
                            "resultado": 0,
                            "resultado_texto": 0,
                            "imagem": ""
                        },
                        {
                            "nome": "PinePr",
                            "resultado": 0,
                            "resultado_texto": 0,
                            "imagem": ""
                        },
                        {
                            "nome": "PinePr",
                            "resultado": 0,
                            "resultado_texto": 0,
                            "imagem": ""
                        },
                    ],
                    "tabelas": {
                        "theme": [
                            {
                                "name": "Preços na Argentina em novembro vão a menor nível mensal desde 2020",
                                "index": 0,
                                "pr_impact": 0,
                                "reach": 0,
                                "impressions": 0,
                                "results": 0,
                                "percent": 0,
                            },
                        ],
                        "initiatives": [
                            {
                                "name": "Preços na Argentina em novembro vão a menor nível mensal desde 2020",
                                "index": 0,
                                "pr_impact": 0,
                                "reach": 0,
                                "impressions": 0,
                                "results": 0,
                                "percent": 0,
                            },
                        ],
                    },
                    "earned": {
                        "value": 0,
                        "percent": 0
                    },
                    "organic": {
                        "value": 0,
                        "percent": 0
                    },
                    "okrs": [],
                    "posts": [],
                    "brands": [
                        {
                            "name": "Jaguar",
                            "value": 0,
                            "results": 0,
                        },
                        {
                            "name": "Range Rover",
                            "value": 0,
                            "results": 0,
                        },
                        {
                            "name": "JLR",
                            "value": 0,
                            "results": 0,
                        },
                        {
                            "name": "Defender",
                            "value": 0,
                            "results": 0,
                        },
                        {
                            "name": "Discovery",
                            "value": 0,
                            "results": 0,
                        }
                    ],
                    "competition": [],
                    "maps": []
                }
            )
        } finally {
            if(optionsTemplate.length>0) {
                setLoading(false);
            }

            if(options) {
                setLoading(false);
            }
        }
    })

    useEffect(() => {
        fetchData();
    },[params.to,params.from])

    if(loading) return (
        <Modal open={loading}>
            <Box sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100vh'}}>
                <div className="loader"></div>
            </Box>
        </Modal>
    );

    const posts=JSON.parse(selected?.textos?.posts||'{}');

    function groupIntoChunks(array,chunkSize) {
        const output=[];
        let currentChunk=[];

        array?.forEach((item,index) => {
            currentChunk.push(item);

            if((index+1)%chunkSize===0||index===array.length-1) {
                output.push(currentChunk);
                currentChunk=[];
            }
        });

        return output;
    }

    return (
        <Div sx={{borderRadius: '5px'}}>
            <Div sx={{width: '100%',height: '88.7vh',}}>
                <Div sx={{width: '100%',height: '45%',borderRadius: '5px',backgroundImage: 'url(https://i.imgur.com/r9g9iS0.png)',backgroundSize: 'cover',backgroundPosition: 'center',position: 'relative'}}>
                    <Div sx={{background: 'linear-gradient(0deg,#000,rgba(0,0,0,.06666666666666667) 25%,transparent)',position: 'absolute',width: '100%',height: '100%'}} />
                    <Grid container sx={{height: '100%',padding: '35px'}}>
                        <Grid item sm={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'space-between'}}>
                            <Div sx={{display: 'flex', alignItems: 'center', height: '40px'}}>
                                <Tooltip title="Voltar para Atividades" placement="right">
                                <IconButton onClick={() => {
                                    router('/dashboard/jlr/atividades_pinePr')
                                }} sx={{color: 'white',backgroundColor: 'rgba(0,0,0,.5333333333333333)',marginRight: '10px', width: '40px', height: '40px', borderRadius: '5px', ':hover': {
                                    backgroundColor: 'rgba(0,0,0,.7333333333333333)',
                                }}}>
                                    <ChevronLeft />
                                </IconButton>
                                </Tooltip>
                                <Typography variant="h1" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '15px'}}>GRUPO JAGUAR LAND ROVER . JLR . 24.25</Typography>
                            </Div>
                            <Autocomplete
                                value={selected}
                                disableClearable
                                onChange={(event,newValue) => {
                                    setSelected(newValue);
                                    const query={
                                        from: format(newValue.from,'yyyy-MM-dd'),
                                        to: format(newValue.to,'yyyy-MM-dd'),
                                    };

                                    const url=qs.stringifyUrl({
                                        url: pathname,
                                        query
                                    },{skipNull: true,skipEmptyString: true});

                                    router(url);
                                }}
                                options={optionsTemplate}
                                getOptionLabel={(option) => option.label}
                                sx={{
                                    width: '220px',color: 'black','& [class*="MuiInputBase-root-MuiOutlinedInput-root"]': {
                                        backgroundColor: '#00000000 !important',
                                    },'& .MuiOutlinedInput-root': {padding: '6px'},'& .MuiInputBase-input': {color: 'black',backgroundColor: '#00000000 !important'}
                                }}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </Grid>
                        <Grid item sm={6} sx={{zIndex: '2'}}>
                            <Typography variant="h2" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '34px'}}>Relatório | JLR</Typography>
                        </Grid>
                    </Grid>
                </Div>
                <Div sx={{width: '100%',height: '60%',background: 'black'}}>
                    <Grid container sx={{height: '100%',padding: '20px',alignItems: 'center'}}>
                        <Grid item md={2} lg={2.2} xl={2.2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'Favorite'} value={`${(data?.sentiment_positive?.value||0).toFixed(2)}%`} label={'SENTIMENT'} percentage={`${(data?.sentiment_positive?.percent*100||0).toFixed(2)}% AVG.`} info={'Tonalidade do resultado (muito positivo, positivo, negativo e neutro).'} />
                        </Grid>
                        <Grid item md={2} lg={2.2} xl={2.2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'Language'} value={formatterMoney({cifrao: false,valor: data?.media_outlet?.value||0})} label={'MEDIA OUTLET'} percentage={`${(data?.media_outlet?.percent*100||0).toFixed(2)}% AVG.`} />
                        </Grid>
                        <Grid item md={2} lg={2.2} xl={2.2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'Groups'} value={formatterMoney({cifrao: false,valor: data?.absolute_reach?.value||0})} label={'ABSOLUTE REACH'} percentage={`${(data?.absolute_reach?.percent*100||0).toFixed(2)}% AVG.`} info='Número de visualizações únicas dos resultados.' />
                        </Grid>
                        <Grid item md={3} lg={2.2} xl={2.2} sx={{zIndex: '2'}}>
                            <CardAnalytics iconName={'AttachMoney'} value={formatterMoney({cifrao: true,valor: data?.ave?.value||0})} label={'AVE'} percentage={`${(data?.ave?.percent*100||0).toFixed(2)}% AVG.`} info='Valor estimado da publicidade, para resultados de Tradicional Media esse valor é baseado na centimetragem da matéria.' />
                        </Grid>
                        <Grid item md={3} lg={3.2} xl={3.2} spacing={2} sx={{zIndex: '2'}}>
                            <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                                <Typography variant="h4" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '18px'}}>VISÃO GERAL</Typography>
                                <Typography>{
                                    selected? <div dangerouslySetInnerHTML={{__html: selected.textos?.visao_geral}} />:''
                                }</Typography>
                            </Div>
                        </Grid>
                    </Grid>
                </Div>
            </Div>
            <Div sx={{width: '100%',background: 'white'}}>
                <Grid container sx={{height: '100%',padding: '20px',alignItems: 'center',paddingBottom: '30px'}} spacing={2}>
                    <Grid item xs={12} lg={7.8} xl={7} sx={{zIndex: '2',marginRight: '20px'}}>
                        <Div sx={{width: '100%',height: '300px'}}>
                            <Card sx={{borderRadius: '15px',boxShadow: '#5857597d 0px 0px 1.25rem 3px',height: '100%'}}>
                                <CardContent>
                                    <Chart
                                        options={{
                                            colors: ['#612466','#e82276','#00a29b','#b5b72a'],
                                            plotOptions: {
                                                area: {
                                                    borderRadius: 2,
                                                    columnWidth: '80%',
                                                    dataLabels: {
                                                        position: 'top', // top, center, bottom
                                                        orientation: 'vertical', // horizontal, vertical
                                                    },
                                                }
                                            },
                                            dataLabels: {
                                                enabled: true,
                                                offsetY: 2.5,
                                                style: {
                                                    fontSize: '12px',
                                                    colors: ['black']
                                                }
                                            },
                                            chart: {
                                                id: "Serviços Extras",
                                                background: '#fff',
                                                redrawOnParentResize: true
                                            },
                                            xaxis: {
                                                categories: data?.graficos?.frequencias?.map(a => format(new Date(a.date),'dd/MM/yy')),
                                            }
                                        }}
                                        series={[
                                            {
                                                name: "Resultado",
                                                data: data?.graficos?.frequencias?.map(a => a.value),
                                            },
                                        ]}
                                        type="area"
                                        height={250}
                                    />
                                </CardContent>
                            </Card>
                        </Div>
                    </Grid>
                    <Grid item xs={12} lg={3.8} xl={4.8} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'black',fontSize: '18px'}}><span style={{color: 'black'}}>FREQUÊNCIAS</span></Typography>
                            <Typography>
                                {selected? <div dangerouslySetInnerHTML={{__html: selected.textos?.frequencias}} />:''}
                            </Typography>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',padding: '20px 0px 20px 0px',background: 'black'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}} spacing={2}>
                    <Grid item xs={12} lg={12} xl={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '24px',mb: '40px'}}>METRICS ABSORPTION</Typography>
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={1.7} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'EmojiEvents'} value={data?.tiers?.['1']?.value||0} label={'TIER 1'} percentage={`${(data?.tiers?.['1']?.percent*100||0).toFixed(2)}% AVG.`} results={true} where='where[tier][i]=1' info={'Relevância e engajamento de um veículo, podendo ser classificado entre Tier 1 (muito relevante).'} />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={1.7} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'EmojiEvents'} value={data?.tiers?.['2']?.value||0} label={'TIER 2'} percentage={`${(data?.tiers?.['2']?.percent*100||0).toFixed(2)}% AVG.`} results={true} where='where[tier][i]=2' info={'Relevância e engajamento de um veículo, podendo ser classificado entre Tier 2 (relevante).'} />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={1.7} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'EmojiEvents'} value={data?.tiers?.['3']?.value||0} label={'TIER 3'} percentage={`${(data?.tiers?.['3']?.percent*100||0).toFixed(2)}% AVG.`} results={true} where='where[tier][i]=3' info={'Relevância e engajamento de um veículo, podendo ser classificado entre Tier 3 (relevante, porém com pouco engajamento).'} />
                    </Grid>
                    <Grid item xs={2} sm={2} md={2} lg={2} xl={1.7} sx={{zIndex: '2'}}>
                        <CardAnalytics iconName={'Visibility'} value={data?.visual_impact?.value||0} label={'VISUAL IMPACT'} percentage={`${(data?.visual_impact?.percent*100||0).toFixed(2)}% AVG.`} results={true} where='whereNotNull=tipo_impacto_id' info={'Quantidade de resultados que apresentaram elementos visuais da marca.'} />
                    </Grid>
                    <Grid item xs={4} sm={4} md={4} lg={4} xl={5.2}>
                        <Carousel
                            navButtonsAlwaysVisible={true}
                            animation="slide"
                            navButtonsProps={{
                                style: {
                                    backgroundColor: '#cacaca',
                                    padding: '3px',
                                    color: 'black',
                                    margin: '0px 0px',
                                }
                            }}
                            autoPlay={false}
                            sx={{width: '100%',height: '360px',display: 'flex',flexDirection: 'column',justifyContent: 'center'}}
                        >
                            {
                                groupIntoChunks(data?.porta_voz,chunkSize).map((group,groupIndex) => (
                                    <Div key={groupIndex} sx={{display: 'flex',gap: 1,width: '100%'}}>
                                        {
                                            group?.map((representante,index) => (
                                                <Grid key={`${representante.nome} = ${index}`} item lg={12/chunkSize} xl={12/chunkSize} sx={{zIndex: '2'}}>
                                                    <Div sx={{
                                                        display: 'flex',flexDirection: 'column',alignItems: 'center',gap: 1.3,textAlign: 'center','& [class*="MuiAvatar-root"]:hover': {
                                                            backgroundColor: 'transparent !important',
                                                        }
                                                    }}>
                                                        <Avatar sx={{width: '100px',height: '100px',color: 'white',backgroundColor: 'transparent !important'}} src={representante.imagem} />
                                                        <Typography variant="h2" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '34px'}}>{representante.resultado}</Typography>
                                                        <Typography variant="h2" sx={{textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '17px',color: 'white'}}>{representante.nome}</Typography>
                                                        <Typography variant="h2" sx={{color: 'white',textShadow: '.2rem .2rem .2rem rgba(0, 0, 0, .5333333333333333)',fontSize: '17px'}}>{(representante.resultado_texto*100).toFixed(2)}%</Typography>
                                                    </Div>
                                                </Grid>
                                            ))
                                        }
                                    </Div>
                                ))
                            }
                        </Carousel>
                    </Grid>
                    {
                        !data?.porta_voz?.length>0&&(
                            <>
                                {
                                    [1,2,3].map((index) => (
                                        <Grid key={`${index} = ${index}`} item lg={2} xl={1.7} sx={{zIndex: '2'}}>
                                            <Div sx={{display: 'flex',flexDirection: 'column',alignItems: 'center',gap: 1.3,textAlign: 'center'}}>
                                                <Skeleton animation={false} variant="circular" width={100} height={100} sx={{background: 'white'}} />
                                                <Skeleton animation={false} variant="text" width={100} sx={{background: 'white'}} />
                                                <Skeleton animation={false} variant="text" width={100} sx={{background: 'white'}} />
                                                <Skeleton animation={false} variant="text" width={100} sx={{background: 'white'}} />
                                            </Div>
                                        </Grid>
                                    ))
                                }
                            </>
                        )
                    }
                </Grid>
            </Div>
            <Div sx={{width: '100%',background: 'white',padding: '70px'}}>
                <Grid container sx={{height: '100%',alignItems: 'center'}} spacing={2}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex'}}>
                        <Typography variant="h1" sx={{fontSize: '24px',color: 'black'}}>REPRESENTAÇÃO DAS MÉTRICAS</Typography>
                    </Grid>
                    <Grid item lg={12} xl={12} spacing={2} sx={{zIndex: '2'}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography>
                                {
                                    selected? <div dangerouslySetInnerHTML={{__html: selected.textos?.representacao_metricas}} />:''
                                }
                            </Typography>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',padding: '20px 0px 20px 0px',background: 'black'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}} spacing={2} >
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{zIndex: '2',mt: 2}}>
                        <Carousel
                            navButtonsAlwaysVisible={true}
                            animation="slide"
                            navButtonsProps={{
                                style: {
                                    backgroundColor: '#424242',
                                    padding: '3px',
                                    color: 'white',
                                    margin: '0px 0px',
                                }
                            }}
                            autoPlay={false}
                            sx={{width: '100%',height: '360px'}}
                        >
                            <Div sx={{width: '100%',display: 'flex',flexDirection: 'row'}}>
                                <Div sx={{width: '50%'}}>
                                    <CardGraphPie title='Sentiment' labels={data?.graficos?.sentiment?.labels} series={data?.graficos?.sentiment?.series} />
                                </Div>
                                <Div sx={{width: '50%'}}>
                                    <CardGraphPie title='Tier' labels={data?.graficos?.tiers?.labels?.map((item) => `Tier ${item}`)} series={data?.graficos?.tiers?.series} />
                                </Div>
                            </Div>
                            <Div sx={{width: '50%'}}>
                                <CardGraphPie title='Visual Impact' labels={data?.graficos?.visual_impact?.labels} series={data?.graficos?.visual_impact?.series} />
                            </Div>
                        </Carousel>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',padding: '20px 0px 20px 0px',background: 'white'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{fontSize: '24px',color: 'black',mb: '40px'}}>SPECIALTIES & ACTIVATIONS</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={width_1} xl={width_1} spacing={2} sx={{zIndex: '2',transition: 'all 0.2s ease-in-out',display: width_2>4? 'none':'initial'}}>
                        <Div sx={{display: 'flex'}}>
                            <Typography variant="h4" sx={{color: 'black',fontSize: '18px'}}>Theme</Typography>
                            {
                                width_1===12? (
                                    <IconButton sx={{ml: 'auto',padding: '8px',borderRadius: '10px','&:hover': {backgroundColor: 'black !important','& .button-1': {color: 'white'}}}} onClick={() => setWidth_1(4)}>
                                        <Remove className="button-1" sx={{color: 'black',width: '20px',height: '20px'}} />
                                    </IconButton>
                                ):(
                                    <IconButton sx={{ml: 'auto',padding: '8px',borderRadius: '10px','&:hover': {backgroundColor: 'black !important','& .button-2': {color: 'white'}}}} onClick={() => setWidth_1(12)}>
                                        <Add className="button-2" sx={{color: 'black',width: '20px',height: '20px'}} />
                                    </IconButton>
                                )
                            }
                        </Div>
                        <TableContainer sx={{maxHeight: 440}} component={Paper}>
                            <Table sx={{minWidth: 650,maxHeight: 400}} aria-label="simple table">
                                <TableHead sx={{
                                    '.MuiTableRow-head': {
                                        border: 'black !important',
                                    }
                                }}>
                                    <TableRow sx={{border: '1px black !important'}}>
                                        <TableCell sx={{backgroundColor: 'black !important'}}>Name</TableCell>
                                        {/* <TableCell sx={{backgroundColor: 'black !important'}} align="right">Index</TableCell> */}
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">PR Impact</TableCell>
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">Reach</TableCell>
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">Impressions</TableCell>
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">Results</TableCell>
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">%</TableCell>
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">Opc.</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.tabelas?.theme.map((row,index) => (
                                        <TableRow
                                            key={`${row.name} = ${index} = ${row.pr_impact}`}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            {/* <TableCell align="right">{row.index||0}</TableCell> */}
                                            <TableCell align="right">{row.pr_impact||0}</TableCell>
                                            <TableCell align="right">{formatterMoney({cifrao: false,valor: row.reach||0})}</TableCell>
                                            <TableCell align="right">{formatterMoney({cifrao: false,valor: row.impressions||0})}</TableCell>
                                            <TableCell align="right">{formatterMoney({cifrao: false,valor: row.results||0})}</TableCell>
                                            <TableCell align="right">{(row.percent||0).toFixed(2)}%</TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => {
                                                    setAssunto(row.name)
                                                    setOpenResults_1(true)
                                                }} sx={{padding: '8px',borderRadius: '10px','&:hover': {backgroundColor: 'black !important','& .button-3': {color: 'white'}}}}>
                                                    <MoreHoriz className="button-3" sx={{color: 'black',width: '20px',height: '20px'}} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <ListarModal setOpenResults={setOpenResults_1} openResults={openResults_1} rotaBase={'pinepr/clipping'} where={`whereR[tipo_pinepr_assunto][tipo][i]=${assunto}`} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={4} xl={4} spacing={2} sx={{zIndex: '2',transition: 'all 0.2s ease-in-out',display: width_1>4||width_2>4? 'none':'initial'}}>
                        <Div sx={{display: 'flex',gap: 1,justifyContent: 'center'}}>
                            <CardAnalytics color='black' noAvatar={true} gauge={true} iconName={''} value={`${formatterMoney({cifrao: false,valor: +data?.organic?.value||0})}`} label={'ORGANIC'} percentage={`${(data?.organic?.percent||0).toFixed(2)}% AVG.`} />
                            <CardAnalytics color='black' noAvatar={true} gauge={true} iconName={''} value={`${formatterMoney({cifrao: false,valor: +data?.earned?.value||0})}`} label={'EARNED'} percentage={`${(data?.earned?.percent||0).toFixed(2)}% AVG.`} />
                        </Div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={width_2} xl={width_2} spacing={2} sx={{zIndex: '2',transition: 'all 0.2s ease-in-out',display: width_1>4? 'none':'initial'}}>
                        <Div sx={{display: 'flex'}}>
                            <Typography variant="h4" sx={{color: 'black',fontSize: '18px'}}>Initiatives</Typography>
                            {
                                width_2===12? (
                                    <IconButton sx={{ml: 'auto',padding: '8px',borderRadius: '10px','&:hover': {backgroundColor: 'black !important','& .remove-button-2': {color: 'white'}}}} onClick={() => setWidth_2(4)}>
                                        <Remove className="remove-button-2" sx={{color: 'black',width: '20px',height: '20px'}} />
                                    </IconButton>
                                ):(
                                    <IconButton sx={{ml: 'auto',padding: '8px',borderRadius: '10px','&:hover': {backgroundColor: 'black !important','& .remove-button-2': {color: 'white'}}}} onClick={() => setWidth_2(12)}>
                                        <Add className="remove-button-2" sx={{color: 'black',width: '20px',height: '20px'}} />
                                    </IconButton>
                                )
                            }
                        </Div>
                        <TableContainer sx={{maxHeight: 440}} component={Paper}>
                            <Table sx={{minWidth: 650,maxHeight: 400}} aria-label="simple table">
                                <TableHead sx={{
                                    '.MuiTableRow-head': {
                                        border: 'black !important',
                                    }
                                }}>
                                    <TableRow sx={{border: '1px black !important'}}>
                                        <TableCell sx={{backgroundColor: 'black !important'}}>Name</TableCell>
                                        {/* <TableCell sx={{backgroundColor: 'black !important'}} align="right">Index</TableCell> */}
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">PR Impact</TableCell>
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">Reach</TableCell>
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">Impressions</TableCell>
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">Results</TableCell>
                                        <TableCell sx={{backgroundColor: 'black !important'}} align="right">%</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data?.tabelas?.initiatives.map((row,index) => (
                                        <TableRow
                                            key={`${row.name} = ${index}`}
                                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.name}
                                            </TableCell>
                                            {/* <TableCell align="right">{row.index||0}</TableCell> */}
                                            <TableCell align="right">{row.pr_impact||0}</TableCell>
                                            <TableCell align="right">{formatterMoney({cifrao: false,valor: row.reach||0})}</TableCell>
                                            <TableCell align="right">{formatterMoney({cifrao: false,valor: row.impressions||0})}</TableCell>
                                            <TableCell align="right">{formatterMoney({cifrao: false,valor: row.results||0})}</TableCell>
                                            <TableCell align="right">{(row.percent||0).toFixed(2)}%</TableCell>
                                            <TableCell align="right">
                                                <IconButton onClick={() => {
                                                    setAssunto(row.name)
                                                    setOpenResults_2(true)
                                                }} sx={{padding: '8px',borderRadius: '20px','&:hover': {backgroundColor: 'black !important','& .button-3': {color: 'white'}}}}>
                                                    <MoreHoriz className="button-3" sx={{color: 'black',width: '20px',height: '20px'}} />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <ListarModal setOpenResults={setOpenResults_2} openResults={openResults_2} rotaBase={'pinepr/clipping'} where={`whereR[tipo_pinepr_assunto][tipo][i]=${assunto}`} />
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',background: 'black',padding: '20px 0px 20px 0px'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{zIndex: '2',display: 'flex',marginBottom: '-60px',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{fontSize: '24px',color: 'white',mb: '40px'}}>CLIPPING</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{zIndex: '2',mt: 2,width: '100%'}}>
                        <Carousel
                            navButtonsAlwaysVisible={true}
                            animation="slide"
                            autoPlay={false}
                            navButtonsProps={{
                                style: {
                                    backgroundColor: '#424242',
                                    padding: '3px',
                                    color: 'white',
                                    margin: '0px 0px',
                                }
                            }}
                            sx={{width: '100%',height: '360px'}}
                        >
                            <Div sx={{width: '100%',display: 'flex',flexDirection: 'row'}}>
                                <Div sx={{width: '50%'}}>
                                    <CardGraphPie title='Origem' labels={data?.graficos?.origem?.labels} series={data?.graficos?.origem?.series} />
                                </Div>
                                <Div sx={{width: '50%'}}>
                                    <CardGraphPie title='Veículo' labels={data?.graficos?.tipo?.labels} series={data?.graficos?.tipo?.series} />
                                </Div>
                            </Div>
                            <Div sx={{width: '50%'}}>
                                <CardGraphPie title='Tema' labels={data?.graficos?.tema?.labels} series={data?.graficos?.tema?.series} />
                            </Div>
                        </Carousel>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',padding: '20px 0px 20px 0px',background: 'white'}}>
                <Grid container spacing={2} sx={{height: '100%',padding: '70px',alignItems: 'center'}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{fontSize: '24px',color: 'black',mb: '40px'}}>OKRS</Typography>
                    </Grid>
                    <Carousel
                        navButtonsAlwaysVisible={true}
                        animation="slide"
                        navButtonsProps={{
                            style: {
                                backgroundColor: '#cacaca',
                                padding: '3px',
                                color: 'black',
                                margin: '0px 0px',
                            }
                        }}
                        autoPlay={false}
                        sx={{width: '100%',height: '360px'}}
                    >
                        {
                            groupIntoChunks(data?.okrs,chunkSize).map((group,groupIndex) => (
                                <Div key={groupIndex} sx={{display: 'flex',gap: 1,width: '100%'}}>
                                    {
                                        group?.map((okr,index) => (
                                            <Grid key={`${okr.title} = ${index}`} item lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                                                <CardOkr numero={okr.numero} title={okr.titulo} meta={okr.meta} atingido={okr.atingido} percente={okr.percent} />
                                            </Grid>
                                        ))
                                    }
                                </Div>
                            ))
                        }
                    </Carousel>
                </Grid>
            </Div>
            <Div sx={{width: '100%',background: 'black'}}>
                <Grid container sx={{height: '100%',padding: '70px 70px 70px 70px',textAlign: 'start'}} spacing={2}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Div sx={{width: '100%',display: 'flex',flexDirection: 'row',gap: 2}}>
                            <Div sx={{width: '33%'}}>
                                <Typography>
                                    {
                                        selected? <div dangerouslySetInnerHTML={{__html: posts?.[0]?.texto}} />:''
                                    }
                                </Typography>
                            </Div>
                            <Div sx={{width: '33%'}}>
                                <Typography>
                                    {
                                        selected? <div dangerouslySetInnerHTML={{__html: posts?.[1]?.texto}} />:''
                                    }
                                </Typography>
                            </Div>
                            <Div sx={{width: '33%'}}>
                                <Typography>
                                    {
                                        selected? <div dangerouslySetInnerHTML={{__html: posts?.[2]?.texto}} />:''
                                    }
                                </Typography>
                            </Div>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',background: 'white',padding: '20px 0px 20px 0px'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}}>
                    <Grid item lg={12} xl={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{color: 'black',fontSize: '24px',marginBottom: '40px'}}>TOP POSTS</Typography>
                    </Grid>
                    {
                        data?.posts?.map((post,index) => (
                            <Grid key={`${post.name} = ${index}`} item xs={4} sm={4} md={4} lg={4} xl={4} spacing={2} sx={{zIndex: '2'}}>
                                <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3,cursor: 'pointer'}} onClick={() => {
                                    window.open(post.link,'_blank')
                                }}>
                                    <Typography variant="h4" sx={{color: 'black',fontSize: '18px',height: '35px',width: '90%'}}>{post.name}</Typography>
                                    <Div sx={{display: 'flex',gap: 1.3}}>
                                        <img width={'95%'} height={'250px'} style={{borderRadius: '10px'}} src={post.src[post.src.length-1]} alt="Sentiment" />
                                    </Div>
                                </Div>
                            </Grid>
                        ))
                    }
                </Grid>
            </Div>
            <Div sx={{width: '100%',background: 'black',padding: '20px 0px 20px 0px'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{zIndex: '2',display: 'flex',justifyContent: 'center'}}>
                        <Typography variant="h1" sx={{color: 'white',fontSize: '24px',mb: '30px'}}>HOUSE OF BRANDS</Typography>
                    </Grid>
                    {
                        data?.brands?.map((brand,index) => (
                            <Grid key={`${brand.name} = ${index}`} item xs={2.4} sm={2.4} md={2.4} lg={2.4} xl={2.4} spacing={2} sx={{zIndex: '2'}}>
                                <CardAnalytics noAvatar={true} iconName={'DirectionsCar'} value={`${brand.value}`} label={`${brand.name}`} percentage={`${(brand.results||0).toFixed(2)}%`} />
                            </Grid>
                        ))
                    }
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{zIndex: '2',display: 'flex',mt: 6,mb: 1}}>
                        <Typography variant="h1" sx={{fontSize: '16px',color: 'white',mb: '20px'}}>REPRESENTAÇÃO DOS MERCADOS</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} sx={{zIndex: '2',display: 'flex',mb: '5px'}}>
                        <Typography>
                            {
                                selected? <div dangerouslySetInnerHTML={{__html: selected.textos?.representacao_mercados}} />:''
                            }
                        </Typography>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',background: 'white'}}>
                <Grid container sx={{height: '100%',padding: '70px',alignItems: 'center'}}>
                    <Grid item xs={8.5} sm={8.5} md={8.5} lg={8.5} xl={8.5}>
                        <Carousel
                            navButtonsAlwaysVisible={true}
                            animation="slide"
                            navButtonsProps={{
                                style: {
                                    backgroundColor: '#cacaca',
                                    padding: '3px',
                                    color: 'black',
                                    margin: '0px 0px',
                                }
                            }}
                            autoPlay={false}
                            sx={{width: '100%',height: '360px',display: 'flex',flexDirection: 'column',justifyContent: 'center'}}
                        >
                            {
                                groupIntoChunks(data?.competition,4).map((group,groupIndex) => (
                                    <Div key={groupIndex} sx={{display: 'flex',gap: 1,width: '100%'}}>
                                        {
                                            group?.map((competitor,index) => (
                                                <Grid key={`${competitor.name} = ${index}`} item xs={3} sm={3} md={3} lg={3} xl={3} sx={{zIndex: '2'}}>
                                                    <CardAnalytics color='black' iconName={'HomeWork'} value={competitor.value} label={competitor.name} percentage={`${(competitor.results*100).toFixed(2)}%`} noAvatar results where={`whereR[tipo_concorrente][id][i]=${competitor?.tipo_concorrente_id}`} />
                                                </Grid>
                                            ))
                                        }
                                    </Div>
                                ))
                            }
                        </Carousel>
                    </Grid>
                    <Grid item xs={3} sm={3} md={3} lg={3} xl={3} spacing={2} sx={{zIndex: '2',mt: 4,ml: 4}}>
                        <Div sx={{display: 'flex',flexDirection: 'column',gap: 1.3}}>
                            <Typography variant="h4" sx={{color: 'black',fontSize: '18px'}}>CITAÇÃO DA CONCORRÊNCIA</Typography>
                            <Typography>
                                {
                                    selected? <div dangerouslySetInnerHTML={{__html: selected.textos?.citacao_concorrencia}} />:''
                                }
                            </Typography>
                        </Div>
                    </Grid>
                </Grid>
            </Div>
            <Div sx={{width: '100%',height: '100vh',background: 'black'}}>
                <MapContainer center={[-23.5505,-46.6333]} zoom={4} doubleClickZoom={false} zoomControl={false} dragging={false} maxZoom={18} minZoom={3} scrollWheelZoom={false} style={{height: '100%',width: '100%'}}>
                    <TileLayer
                        attribution='&copy; <a href="http://localhost:3000/mapa">Resolui TI</a>'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    {
                        data?.maps?.map((estado,index) => {
                            return (
                                <Marker key={`${mapsLatLng[estado.name].lat} = ${index}`} position={[mapsLatLng[estado.name].lat,mapsLatLng[estado.name].lng]}>
                                    <Popup>
                                        {estado.name}
                                    </Popup>
                                </Marker>
                            )
                        })
                    }
                    {
                        !data?.maps?.length>0&&console.log(data?.maps)
                    }
                </MapContainer>
            </Div>
        </Div>
    );
}