import React from 'react';
import {Typography,lighten,useMediaQuery} from "@mui/material";
import Div from 'ui-component/layouts/components/Div';
import {Area,AreaChart,Line,LineChart,ResponsiveContainer} from 'recharts';
import {Card,CardHeader,CardContent} from "@mui/material";
import {formatCurrency} from 'utils/formatCurrency';
import CountUp from 'react-countup';

const getColorObject=(color) => {

    if(typeof color==="string") {
        return {
            type: "color",
            value: color
        }
    }

    if(color.length===1) {
        return {
            type: "color",
            value: color
        }
    }

    return {
        type: "gradient",
        value: color
    };
};

const getBgColorStyle=(colors,gradientDir) => {
    const colorObject=getColorObject(colors);
    if(!colorObject)
        return null;

    if(colorObject.type==="color") {
        return {backgroundColor: colorObject.value};
    }
    else if(colorObject.type==="gradient") {
        if(gradientDir)
            return {backgroundImage: `linear-gradient(${gradientDir}, ${colorObject.value.join()})`}
        return {backgroundImage: `linear-gradient(${colorObject.value.join()})`}
    }
};


const getBgImageStyle=(imgSrc) => {
    return {
        background: `url(${imgSrc}) no-repeat center`,
        backgroundSize: 'cover'
    }
};


const CardQuick=(
    {
        title,subheader,avatar,action,
        bgColor,bgImage,bgGradientDir,
        headerSx,noWrapper,wrapperSx,
        reverse,sx,children,
        ...restProps
    }) => {
    const [bgStyle,setBgStyle]=React.useState({});

    React.useEffect(() => {
        if(bgImage) {
            setBgStyle(getBgImageStyle(bgImage));
        } else if(bgColor) {
            setBgStyle(getBgColorStyle(bgColor,bgGradientDir));
        } else {
            setBgStyle({});
        }
    },[bgColor,bgImage,bgGradientDir]);

    return (
        <Card sx={{...bgStyle,position: "relative",...sx}} {...restProps}>
            {
                (action||title||avatar)&&!reverse&&
                <CardHeader
                    title={title}
                    subheader={subheader}
                    action={action}
                    avatar={avatar}
                    sx={{
                        zIndex: 2,
                        position: "relative",
                        pb: 0,
                        height: '55px',
                        p: 0,
                        pt: 1,
                        pr: 1,
                        pl: 3,
                        ...headerSx,
                    }}
                />
            }
            {
                noWrapper? children:
                    <CardContent sx={{...wrapperSx,zIndex: 2,position: "relative"}}>{children}</CardContent>
            }
            {
                (action||title||avatar)&&reverse&&
                <CardHeader
                    title={title}
                    subheader={subheader}
                    action={action}
                    avatar={avatar}
                    sx={{
                        zIndex: 2,
                        position: "relative",
                        height: '55px',
                        borderBottomColor: (theme) => theme.palette.divider,
                        ...headerSx
                    }}
                />
            }
        </Card>
    );
};


const Graph=({color,trendingData}) => {
    return (
        <ResponsiveContainer width="100%" height={100} style={{marginTop: '-15px'}}>
            <AreaChart data={trendingData} margin={{top: 0,right: 0,left: 0,bottom: 0}}>
                <Area dataKey="price" strokeWidth={3} stroke={lighten(color[0],0.2)} fill="white" fillOpacity={0.2} />
            </AreaChart>
        </ResponsiveContainer>
    );
};

const GraphReverse=({trendingData}) => {
    return (
        <ResponsiveContainer width="100%" height={50}>
            <LineChart data={trendingData} margin={{top: 0,right: 0,left: 0,bottom: 0}}>
                <Line dataKey="price"
                    filter="url(#shadow)"
                    type="monotone"
                    dot={null}
                    strokeWidth={3}
                    stackId="2"
                    fill="white"
                    stroke="white"
                />
            </LineChart>
        </ResponsiveContainer>
    );
};



const Properties=({currency,percent,title,value,description,bgColor,trending='up',reverse,sx}) => {
    const xl=useMediaQuery((theme) => theme.breakpoints.down('xl'));

    const objTrending={
        'down': [
            {month: 'Jan',price: 900},
            {month: 'Feb',price: 870},
            {month: 'Mar',price: 750},
            {month: 'Apr',price: 600},
            {month: 'May',price: 400},
            {month: 'Jun',price: 350},
            {month: 'Jul',price: 200},
            {month: 'Aug',price: 50},
        ],
        'up': [
            {month: 'Jan',price: 200},
            {month: 'Feb',price: 300},
            {month: 'Mar',price: 550},
            {month: 'Apr',price: 600},
            {month: 'May',price: 650},
            {month: 'Jun',price: 700},
            {month: 'Jul',price: 800},
            {month: 'Aug',price: 900},
        ],
        'equal': [
            {month: 'Jan',price: 0},
            {month: 'Feb',price: 0},
            {month: 'Mar',price: 0},
            {month: 'Apr',price: 0},
            {month: 'May',price: 0},
            {month: 'Jun',price: 0},
            {month: 'Jul',price: 0},
            {month: 'Aug',price: 0},
        ],
        'line': [
            {month: 'Jan',price: 800},
            {month: 'Feb',price: 1600},
            {month: 'Mar',price: 1100},
            {month: 'Apr',price: 2600},
            {month: 'May',price: 1900},
            {month: 'Jun',price: 3200},
        ]
    }

    const data=objTrending[trending];
    let titleR=reverse? <Typography fontWeight={"500"} variant={"h2"} color={"common.white"}>{value}</Typography>:<Typography variant={"h2"} mb={0} sx={{fontSize: 20,color: "white",letterSpacing: 1.5,textWrap: 'balance'}}>{title}</Typography>

    // if(title.includes('Ticket') || title.includes('Taxa')) {
    //     const titleSplit = title.split('(');
    //     titleR = (
    //         <Typography variant={"h2"} mb={0} sx={{fontSize: 20, color: "white", letterSpacing: 1.5}}>
    //             {titleSplit[0]}
    //             <br />
    //             <span style={{fontSize: '0.8em'}}>{'(' + titleSplit[1]}</span>
    //         </Typography>
    //     );
    // }

    const subheader=reverse? <Typography variant={"h4"} color={"common.white"} mb={0}>{title}</Typography>:null

    return (
        <CardQuick
            title={titleR}
            subheader={subheader}
            sx={{color: "white",...sx,height: xl? '175px':'185px',borderRadius: '15px',boxShadow: '#5857597d 0px 0px 1.25rem 3px'}}
            bgColor={bgColor}
            wrapperSx={{p: 0,'&:last-child': {p: 0},height: '100%'}}
            reverse={reverse}
        >
            {
                reverse?
                    <Div sx={{p: 2,pb: 0}}><GraphReverse color={bgColor} trendingData={data} /></Div>
                    :(
                        <>
                            <Div
                                sx={{
                                    pl: 3,
                                    pt: 1,
                                    zIndex: 1,
                                }}
                            >
                                <Typography variant={"h2"} sx={{fontSize: xl? 24:30}} color={"white"}>
                                    {
                                        currency&&<CountUp preserveValue start={0} end={value} decimal='2' decimalPlaces={2} formattingFn={formatCurrency} />
                                    }
                                    {
                                        percent&&<CountUp preserveValue start={0} end={value} decimal='2' decimalPlaces={2} suffix="%" />
                                    }
                                    {
                                        !currency && !percent&&<CountUp preserveValue start={0} end={value} decimal='2' decimalPlaces={2} />
                                    }
                                </Typography>
                                {
                                    description&&<Typography variant={"h4"} color={"white"} mb={0}>{description}</Typography>
                                }
                            </Div>

                            <Graph color={bgColor} trendingData={data} />
                        </>
                    )
            }
        </CardQuick>
    );
};

export default Properties;
