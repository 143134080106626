import axiosConfig from 'config/AxiosConfig';
import dayjs from 'dayjs';
import React,{createContext,useState,useContext} from 'react';
import {useEffect} from 'react';
import {useLocation} from 'react-router';
import {useSearchParams} from 'react-router-dom';

const DashboardPageContext=createContext();

export const useDashboardPage=() => useContext(DashboardPageContext);

export const DashboardPageProvider=({children}) => {
  const [data,setData]=useState([]);
  const [years, setYears] = useState([]);
  const [searchParamsGet]=useSearchParams();
  const endDate=searchParamsGet?.get('endDate') ? dayjs(searchParamsGet?.get('endDate')) : dayjs();
  const [DateFilterConfig, setDateFilterConfig]= useState({
    startDate: endDate.subtract(3, 'year'),
    endDate: endDate,
  });
  const [isLoading,setIsLoadingS]=useState(true);
  const [hasScrollbar, setHasScrollbar] = useState(false); // Verifica se tem scrollbar
  const pathname=useLocation().pathname

  const setIsLoading=(value) => {
    setHasScrollbar(false);
    setIsLoadingS(value);
  }

  useEffect(() => {
    if(pathname==='/dashboard-resumo') {

      const getYearsInRange = () => {
        // const year_inicial = dayjs(DateFilterConfig.startDate).year();
        const year_inicial = dayjs(DateFilterConfig.endDate).year() - 3
        const year_final = dayjs(DateFilterConfig.endDate).year();

        let years_data = [];

        for (let i = year_inicial; i <= year_final; i++) {
          years_data.push(i);
        }

        return years_data
      }


      const fetchData=async () => {
        try {
          setIsLoading(true);
          const years_data = getYearsInRange()
          setYears(years_data)
          const response=await axiosConfig.get(`dashboard/comercial/pinepr?where[ano]=${years_data.join(',')}`);
          setData(response.data?.data?.objetos[0]);
        } catch(e) {
          console.log(e);
          setData({
            propostas_fechadas_valor_head: [],
          });
        } finally {
          setIsLoading(false);
        }
      }
      fetchData();
    } else {
      setData([]);
      setIsLoading(false);
    }
  },[pathname, DateFilterConfig])

  return (
    <DashboardPageContext.Provider value={{
      data,isLoading, setIsLoading, hasScrollbar, setHasScrollbar, setDateFilterConfig, DateFilterConfig, years
    }}>
      {children}
    </DashboardPageContext.Provider>
  );
};
