import React,{useState} from 'react';
import {
  Table,
  TableBody,
  TableContainer,
  TableCell,
  TableRow,
  Paper,
  TablePagination,
  useTheme,
  Pagination,
  Modal,
  TableHead,
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useDispatch} from 'react-redux';
import {showSnackbar} from 'store/SnackBar/actions';
import {Box} from '@mui/system';
import 'dayjs/locale/pt';
import axiosConfig from 'config/AxiosConfig';
import {formatCurrency} from 'utils/formatCurrency';
import {formatterMoney} from 'utils/formatterMoney';

// ------------------         COMPONENT USER ROW         ------------------ //

const dataBr=(date) => {
  // date = '2021-10-01' || 2024-06-03T18:11:03.000000Z
  let dateSplit='2021-10-01';
  if(!date) return '';
  if(date?.includes('T')) {
    const dateSplited=date.split('T');
    dateSplit=dateSplited[0].split('-');
  } else if(date?.split(' ')?.length>0) {
    dateSplit=date.split(' ')[0].split('-');
  } else {
    dateSplit=date?.split('-');
  }
  const ano=dateSplit[0];
  const mes=dateSplit[1];
  const dia=dateSplit[2];

  return `${dia}/${mes}/${ano}`;
}

const UserRow=({user}) => {
  return (
    <>
      <TableRow>
        <TableCell>
          {user?.tipo_pinepr_canal?.tipo}
        </TableCell>
        <TableCell>
          {user?.tipo_pinepr_veiculo?.tipo}
        </TableCell>
        <TableCell>
          {dataBr(user?.data)}
        </TableCell>
        <TableCell>
          {formatCurrency(user?.eq_comercial)}
        </TableCell>
        <TableCell>
          {formatterMoney({cifrao: false, valor: Number(user?.alcance)})}
        </TableCell>
        <TableCell>
          {user?.titulo}
        </TableCell>
        <TableCell>
          {user?.avaliacao_valor}
        </TableCell>
        <TableCell>
          <a href={user?.link_externo} target="_blank" rel="noreferrer">Link</a>
        </TableCell>
      </TableRow>
    </>
  );
};

// ------------------         COMPONENT USER TABLE        ------------------ //

const UserTable=({rotaBase,name, where}) => {
  const theme=useTheme();
  const dispatch=useDispatch();
  const [users,setUsers]=useState([]);
  const [page,setPage]=useState(1);
  const [rowsPerPage,setRowsPerPage]=useState(10);
  const [rowsPerPagePrev,setRowsPerPagePrev]=useState(10);
  const [pagePrev,setPagePrev]=useState(1);
  const [totalItems,setTotalItems]=useState(0);
  const [totalPages,setTotalPages]=useState(0);
  const [filteredUsers,setFilteredUsers]=useState(users);
  const [removeFilter,setRemoveFilter]=useState(false);
  const [loading,setLoading]=useState(false);
  const [searchParams,setSearchParams]=useSearchParams({
    limit: 10,
    page: 1,
  });
  const limits=[10,50,100,500];

  useEffect(() => {
    const limit=searchParams.get('limit');
    const page=searchParams.get('page');

    localStorage.setItem('redirect','');

    if(limit) {
      limits.forEach((value) => {
        if(value===parseInt(limit)) {
          setRowsPerPage(parseInt(limit));
        }
      });
    } else {
      setSearchParams({from: searchParams.get('from'),to: searchParams.get('to'),page: searchParams.get('page')||1,limit: 10});
    }
    if(page) {
      setPage(parseInt(page));
    } else {
      setSearchParams({from: searchParams.get('from'),to: searchParams.get('to'),page: 1,limit: searchParams.get('limit')||10});
    }
  },[searchParams]);

  useEffect(() => {
    const fetchProgramas=async () => {
      setLoading(true);
      try {
        const method='get';
        const listar=localStorage.getItem('listar')
        let responseData={};

        const limit=searchParams.get('limit')||1;
        const page=searchParams.get('page')||10;

        const from = searchParams.get('from');
        const to = searchParams.get('to');

        localStorage.setItem('redirect','');

        responseData=await axiosConfig.get(`/${rotaBase}/listar?page=${page}&limit=${limit}&${where}&whereData[data][entre]=${from},${to}`);

        const objetos=responseData.data.data.objetos;
        if(listar&&listar!==undefined) {
          const dataCadastrado=await axiosConfig[method](`/${rotaBase}/listar?page=${responseData.data.data.paginacao.lastPage}&limit=10&${where}&whereData[data][entre]=${from},${to}`,{});
          objetos.unshift(dataCadastrado.data.data.objetos[dataCadastrado.data.data.objetos.length-1]);
          objetos.pop()
          localStorage.removeItem('listar');
        }
        setTotalItems(responseData?.data?.data?.paginacao?.total);
        setTotalPages(responseData?.data?.data?.paginacao?.lastPage);
        setUsers(responseData.data.data.objetos)
      } catch(error) {
        console.log(error);
        dispatch(showSnackbar(`Erro ao buscar dados ${name}`,'error'));
      } finally {
        setLoading(false);
      }
    }
    fetchProgramas();
  },[])

  useEffect(() => {
    if(page!==pagePrev||rowsPerPage!==rowsPerPagePrev||removeFilter) {
      setRemoveFilter(false);
      applyFilters();
    }
  },[page,rowsPerPage,removeFilter]);

  const applyFilters=async (lastPageApply) => {
    setLoading(true);
    try {
      const from = searchParams.get('from');
      const to = searchParams.get('to');
      const pageFilter=lastPageApply? lastPageApply:page;
      const response=await axiosConfig.get(`/${rotaBase}/listar?page=${pageFilter}&limit=${rowsPerPage}&${where}&whereData[data][entre]=${from},${to}`);
      const lastPage=response?.data?.data?.paginacao?.lastPage;
      if(Number(lastPage)<Number(pageFilter)) {
        setPage(Number(lastPage));
        setPagePrev(Number(lastPage));
        setSearchParams({from: searchParams.get('from'),to: searchParams.get('to'),page: Number(lastPage),limit: searchParams.get('limit')});
        return applyFilters(lastPage);
      }
      setTimeout(() => {
        setFilteredUsers(response.data.data.objetos);
        setTotalItems(response?.data?.data?.paginacao?.total);
        setTotalPages(response?.data?.data?.paginacao?.lastPage);
        setPagePrev(pageFilter);
        setRowsPerPagePrev(rowsPerPage);
        setLoading(false);
      },50);
    } catch(error) {
      console.log(error);
      setTimeout(() => {
        dispatch(showSnackbar(`Erro ao filtrar ${name}`,'error'));
        setLoading(false);
      },50);
    }
  };

  useEffect(() => {
    setFilteredUsers(users);
  },[users]);

  const handleChangePage=(event,newPage) => {
    setPage(newPage);
    setSearchParams({from: searchParams.get('from'),to: searchParams.get('to'),page: newPage,limit: searchParams.get('limit')});
  };

  const handleChangeRowsPerPage=(event) => {
    setSearchParams({from: searchParams.get('from'),to: searchParams.get('to'),page: 1,limit: event.target.value});
    setRowsPerPage(+event.target.value);
    setPage(1);
  };


  return (
    <>
      <MainCard title={name} elevation={3}>
        <Paper sx={{width: '100%',overflow: 'hidden',height: '100%'}}>
          <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
            <TablePagination
              rowsPerPageOptions={[10,50,100,500]}
              component="div"
              labelRowsPerPage={'Linhas por página'}
              labelDisplayedRows={() => ''}
              count={totalItems}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              nextIconButtonProps={{style: {display: "none"}}}
              backIconButtonProps={{style: {display: "none"}}}
              sx={{
                '& .MuiSelect-select': {
                  backgroundColor: `${theme.palette.background.default} !important`
                }
              }}
            />
            <Pagination page={page} onChange={handleChangePage} count={totalPages} variant="outlined" shape="rounded" />
          </Box>
          <TableContainer sx={{borderRadius: 1,maxHeight: 540}}>
            <Table aria-label="customized collapsible table" sx={{minWidth: 1280,maxHeight: 500}}>
              <TableHead sx={{
                '.MuiTableRow-head': {
                  border: 'black !important',
                }
              }}>
                <TableRow sx={{border: '1px black !important'}}>
                  <TableCell sx={{backgroundColor: 'black !important'}}>
                    Publisher
                  </TableCell>
                  <TableCell sx={{backgroundColor: 'black !important'}}>
                    Media Outlet
                  </TableCell>
                  <TableCell sx={{backgroundColor: 'black !important'}}>
                    Date
                  </TableCell>
                  <TableCell sx={{backgroundColor: 'black !important'}}>
                    Ave
                  </TableCell>
                  <TableCell sx={{backgroundColor: 'black !important'}}>
                    Impressions
                  </TableCell>
                  <TableCell sx={{backgroundColor: 'black !important'}}>
                    Title
                  </TableCell>
                  <TableCell sx={{backgroundColor: 'black !important'}}>
                    Sentiment
                  </TableCell>
                  <TableCell sx={{backgroundColor: 'black !important'}}>
                    Link
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody sx={{height: '300px'}}>
                {filteredUsers.map((user,index) => (
                  <>
                    <UserRow
                      index={index}
                      key={`${user.id} = ${index}:${index}`}
                      user={user}
                    />
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{display: 'flex',justifyContent: 'end',marginTop: '25px'}}>
            <Pagination page={page} onChange={handleChangePage} count={totalPages} variant="outlined" shape="rounded" />
          </Box>
        </Paper>
        <Modal open={loading}>
          <Box sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100vh'}}>
            <div className="loader"></div>
          </Box>
        </Modal>
      </MainCard>
    </>
  );
};

export default function Listar({rotaBase,name,where}) {
  return (
    <UserTable rotaBase={rotaBase} name={name} where={where} />
  );
}
