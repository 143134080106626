import {Box, Card, CardHeader,LinearProgress,Typography,linearProgressClasses,styled,} from "@mui/material";
import Div from "ui-component/layouts/components/Div";

const BorderLinearProgress=styled(LinearProgress)(() => ({
    height: 30,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#424242',
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: 'rgb(0, 162, 155)',
    },
}));

export default function CardOkr({numero = 1,title = "Aumentar a visibilidade da marca", meta = 100, atingido = 104, percentage = 100}) {
    return (
        <Card sx={{border: '1px solid black'}}>
            <CardHeader
                sx={{color: 'black',marginLeft: '20px'}}
                title={`KR${numero}`}
                subheader={title}
            />
            <Div>
                <Div sx={{display: 'flex',justifyContent: 'space-evenly',width: '100%'}}>
                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                        <Typography variant="body" sx={{fontSize: '15px',color: '#424242'}}>Meta KR1</Typography>
                        <Typography variant="body" sx={{fontSize: '20px',color: 'black'}}>{meta}</Typography>
                        <Div sx={{display: 'flex',gap: 2,alignItems: 'center'}}>
                            <Box sx={{width: '50px',height: '25px',background: 'black'}}></Box>
                            <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>Meta KR1</Typography>
                        </Div>
                    </Div>
                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2}}>
                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>|</Typography>
                    </Div>
                    <Div sx={{display: 'flex',flexDirection: 'column',gap: 2,textAlign: 'center'}}>
                        <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>KR1 Atingido</Typography>
                        <Typography variant="body" sx={{fontSize: '20px',color: 'black'}}>{atingido}</Typography>
                        <Div sx={{display: 'flex',gap: 2,alignItems: 'center'}}>
                            <Box sx={{width: '50px',height: '25px',background: 'rgb(0, 162, 155)'}}></Box>
                            <Typography variant="body" sx={{fontSize: '15px',color: 'black'}}>KR1 Atingido</Typography>
                        </Div>
                    </Div>
                </Div>
                <Div sx={{width: '100%',padding: '20px',paddingTop: 0}}>
                    <BorderLinearProgress sx={{margin: '20px',marginBottom: '0px'}} variant="determinate" value={percentage} />
                </Div>
            </Div>
        </Card>
    )
}