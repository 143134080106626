import {Box,Button,GlobalStyles,Grid,Modal,Pagination,Table,TableBody,TableCell,TableContainer,TablePagination,TableRow,TextField,Typography,keyframes,useMediaQuery,useTheme} from "@mui/material";
import Div from "../../../ui-component/layouts/components/Div";
import TableHeadComponent from "components/TableHeadComponent";
import {useEffect} from "react";
import {useState} from "react";
import {IconFilter} from "@tabler/icons";
import {useDispatch} from "react-redux";
import {showSnackbar} from "store/SnackBar/actions";
// import Chart from "react-google-charts";
import {useDateFilterConfig} from "contexts/DateFilterConfig";
import {useLocation} from "react-router";
import Carousel from "react-material-ui-carousel";
// import CanvasJSReact from '@canvasjs/react-charts';
import {useSearchParams} from "react-router-dom";
import {AgCharts} from "ag-charts-react";
import axiosConfig from "config/AxiosConfig";

// var CanvasJSChart=CanvasJSReact.CanvasJSChart;

const columns=[
    {id: 'data',label: 'Data',minWidth: 170,align: 'center'},
    {id: 'tipo_pinepr_atividade',label: 'Tipo Atividade',minWidth: 170,align: 'center'},
    {id: 'tipo_pinepr_conteudo',label: 'Conteúdo',minWidth: 170,align: 'center'},
    {id: 'detalhes',label: 'Detalhes',minWidth: 170,align: 'center'},
]

const myEffectIn=keyframes`
  0% {
    opacity: 0;
    height: 0px;
  },
  50% {
    opacity: 0;
  },
  100% {
    opacity: 1;
    height: 127px;
  }
`;

const myEffectOut=keyframes`
  0% {
    opacity: 1;
    height: 127px;
  },
  50% {
    opacity: 0;
  },
  100% {
    opacity: 0;
    height: 0px;
  }
`;

const Atividades=() => {
    const [atividades,setAtividades]=useState([])
    const [filterModalOpen,setFilterModalOpen]=useState(false);
    const [openModalAnimation,setOpenModalAnimation]=useState(false);
    const [filters,setFilters]=useState({});
    const [removeFilter,setRemoveFilter]=useState(false);
    const dispatch=useDispatch();
    const [loading,setLoading]=useState(true);
    const [conteudo,setConteudo]=useState([]);
    const [tipoAtividades,setTipoAtividades]=useState([]);
    const [totalRelacionamento,setTotalRelacionamento]=useState(0);
    const {DateFilterConfig}=useDateFilterConfig();
    const pathname=useLocation().pathname;
    const theme=useTheme();
    const lessThan1030=useMediaQuery('(max-width:1030px)');
    const lessThan1590=useMediaQuery('(max-width:1590px)');
    const md=useMediaQuery(theme.breakpoints.up('md'));
    const isMobile=useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet=useMediaQuery(theme.breakpoints.down('md'));
    const chunkSize=isTablet? (isMobile? 1:2):3;
    const [currentIndex,setCurrentIndex]=useState(0);
    const [page,setPage]=useState(0);
    const [rowsPerPage,setRowsPerPage]=useState(10);
    const [rowsPerPagePrev,setRowsPerPagePrev]=useState(10);
    const [pagePrev,setPagePrev]=useState(0);
    const [totalItems,setTotalItems]=useState(0);
    const [totalPages,setTotalPages]=useState(0);
    const [searchParams,setSearchParams]=useSearchParams({limit: 10,page: 0});
    const limits=[10,50,100,500];
    // const customizat

    useEffect(() => {
        dispatch({type: '@customization/SET_MENU',opened: false});
    },[dispatch]);

    useEffect(() => {
        const limit=searchParams.get('limit');
        const page=searchParams.get('page');

        if(limit) {
            limits.forEach((value) => {
                if(value===parseInt(limit)) {
                    setRowsPerPage(parseInt(limit));
                }
            });
        } else {
            setSearchParams({page: searchParams.get('page')||0,limit: 10});
        }
        if(page) {
            setPage(parseInt(page));
        } else {
            setSearchParams({page: 0,limit: searchParams.get('limit')||10});
        }
    },[searchParams]);


    const handleChangePage=(event,newPage) => {
        setPage(newPage);
        setSearchParams({page: newPage,limit: searchParams.get('limit')});
    };

    const handleChangeRowsPerPage=(event) => {
        setSearchParams({page: 0,limit: event.target.value});
        setRowsPerPage(+event.target.value);
        setPage(0);
    };


    const handleResize=() => {
        setTimeout(() => {
            window.dispatchEvent(new Event('resize'));
        },100); // Ajuste o tempo conforme necessário
    };

    useEffect(() => {
        handleResize();
    },[currentIndex]); // Chama handleResize sempre que currentIndex mudar

    function groupIntoChunks(array,chunkSize) {
        const output=[];
        let currentChunk=[];

        array.forEach((item,index) => {
            currentChunk.push(item);

            if((index+1)%chunkSize===0||index===array.length-1) {
                output.push(currentChunk);
                currentChunk=[];
            }
        });

        return output;
    }

    useEffect(() => {
        if(page!==pagePrev||rowsPerPage!==rowsPerPagePrev||removeFilter) {
            setRemoveFilter(false);
            applyFilters();
        }
    },[page,rowsPerPage,removeFilter]);

    useEffect(() => {
        applyFilters();
    },[
        DateFilterConfig,
        DateFilterConfig.startDate,
        DateFilterConfig.endDate,
    ]);

    const width=() => {
        if(lessThan1030) {
            return '300px';
        } else if(lessThan1590) {
            return '400px';
        } else {
            return '400px'
        }
    }

    useEffect(() => {
        applyFilters();
    },[])

    useEffect(() => {
        if(removeFilter) {
            setRemoveFilter(false);
            applyFilters();
        }
    },[removeFilter]);

    const handleOpenFilterModal=() => {
        if(filterModalOpen) {
            setOpenModalAnimation(true);
            setTimeout(() => {
                setFilterModalOpen(!filterModalOpen);
                setOpenModalAnimation(false);
            },300);
        } else {
            setFilterModalOpen(!filterModalOpen);
        }
    };

    const applyFilters=async () => {
        setLoading(true);
        let filtrosQuery='';
        Object.keys(filters).map((key) => {
            if(filters[key]!=='') {
                filtrosQuery+=`&${key}=${filters[key]}`;
            }
        });
        const data_inicial=JSON.stringify(DateFilterConfig.startDate).split('T')[0].replace('"','');
        const data_final=JSON.stringify(DateFilterConfig.endDate).split('T')[0].replace('"','');
        try {
            const response=await axiosConfig.get(`/pinepr/atividades/listar?page=${page}&limit=${rowsPerPage}&where[contratos_id]=${pathname.split('/')[2].includes('jlr') ? 2 : 1}${filtrosQuery}&whereData[data][entre]=${data_inicial},${data_final}`, {
                skipAuth: true,
            });
            const responseAtividades=await axiosConfig.get(`/pinepr/graficos_atividades_detalhes/listar?where[contratos_id]=${pathname.split('/')[2].includes('jlr') ? 2 : 1}&whereData[data][entre]=${data_inicial},${data_final}`, {
                skipAuth: true,
            });
            const data=responseAtividades.data.data.objetos[0];
            const counteudoTipo=Object.keys(data.conteudo).map((key) => ({asset: key,amount: data.conteudo[key]}));
            const tipoAtividadesT=Object.keys(data.atividade).map((key) => ({asset: key,amount: data.atividade[key]}));
            const totalRelacionamentos=data.relacionamento;
            const refactorData=response.data.data.objetos.map((item) => {
                return {
                    data: item.data,
                    tipo_pinepr_atividade: item.tipo_pinepr_atividade.tipo,
                    tipo_pinepr_conteudo: item.tipo_pinepr_conteudo.tipo,
                    detalhes: item.detalhes,
                }
            });
            setTimeout(() => {
                setTotalRelacionamento(totalRelacionamentos);
                setConteudo(counteudoTipo);
                setTipoAtividades(tipoAtividadesT);
                setAtividades(refactorData);
                setTotalItems(response.data.data.paginacao.total);
                setTotalPages(response.data.data.paginacao.lastPage);
                setPagePrev(page);
                setRowsPerPagePrev(rowsPerPage);
            },50);
        } catch(error) {
            console.log(error);
            setTimeout(() => {
                dispatch(showSnackbar(`Erro ao filtrar`,'error'));
            },50);
        } finally {
            setLoading(false);
        }
    };

    const handleClearFilters=() => {
        setFilters(
            {
                'whereR[tipo_pinepr_atividade][tipo][like]': '',
                'whereR[tipo_pinepr_conteudo][tipo][like]': '',
            }
        )
        setRemoveFilter(true);
    };

    const filterModal=() => {
        return (
            <Box sx={{animation: filterModalOpen&&!openModalAnimation? `${myEffectIn} 0.3s ease-in-out`:`${myEffectOut} 0.3s ease-in-out`}}>
                <Grid container spacing={2} sx={{padding: 2,marginLeft: 0,marginTop: '0px !important',width: '100%'}}>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{paddingTop: `0 !important`}}>
                        <TextField
                            sx={{paddingTop: `0 !important`,paddingLeft: `0 !important`,'[class*="MuiOutlinedInput-input"]': {color: 'black !important'}}}
                            label={'Atividade'}
                            variant="outlined"
                            size="small"
                            value={filters?.['whereR[tipo_pinepr_atividade][tipo][like]']}
                            onChange={(e) => setFilters({...filters,'whereR[tipo_pinepr_atividade][tipo][like]': e.target.value})}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3} sx={{paddingTop: `0 !important`}}>
                        <TextField
                            sx={{paddingTop: `0 !important`,paddingLeft: `0 !important`,'[class*="MuiOutlinedInput-input"]': {color: 'black !important'}}}
                            label={'Conteúdo'}
                            variant="outlined"
                            size="small"
                            value={filters?.['whereR[tipo_pinepr_conteudo][tipo][like]']}
                            onChange={(e) => setFilters({...filters,'whereR[tipo_pinepr_conteudo][tipo][like]': e.target.value})}
                            fullWidth
                            type={'text'}
                        />
                    </Grid>
                    <Grid sx={{gap: 2,display: 'flex'}} item xs={12} sm={12} md={12} lg={12} xl={12}>
                        <Button
                            variant="contained"
                            onClick={applyFilters}
                        >
                            Filtrar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleClearFilters}
                        >
                            Limpar
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        );
    };

    if(loading) return (
        <Modal open={loading}>
            <Box sx={{display: 'flex',justifyContent: 'center',alignItems: 'center',height: '100vh'}}>
                <div className="loader"></div>
            </Box>
        </Modal>
    );

    return (
        <Div sx={{display: 'flex',flexDirection: 'column',gap: 4,padding: 2}}>
            <GlobalStyles styles={{'.MuiList-root': {color: 'black !important'},'.MuiPaginationItem-root': {color: 'black !important'}}} />
            <Div>
                <Typography variant="h2" color='black' gutterBottom>
                    Atividades - {pathname.split('/')[2].toUpperCase()}
                </Typography>
            </Div>
            {
                !md? (
                    <Carousel navButtonsAlwaysInvisible={true} sx={{width: '100%'}} onChange={(index) => setCurrentIndex(index)} >
                        {groupIntoChunks(['conteudo','atividades','relacionamento'],chunkSize).map((group,groupIndex) => (
                            <Div key={groupIndex} sx={{display: 'flex',gap: 1,width: '100%'}}>
                                {
                                    group.map((item,index) => {
                                        if(item==='conteudo') {
                                            return (
                                                <Div key={`${item}-${index}`} sx={{width: `${100/chunkSize}%`}}>
                                                    {/* <Chart
                                                        width={'100%'}
                                                        height={'400px'}
                                                        chartType="PieChart"
                                                        data={[
                                                            ['Conteudo','Quantidade'],
                                                            ...conteudo.map((item) => [item.label,item.value])
                                                        ]}
                                                        options={{
                                                            title: 'Conteúdo',
                                                        }}
                                                    /> */}
                                                    <AgCharts style={{height: '400px'}} options={{
                                                        data: conteudo,
                                                        title: {
                                                            text: "Conteúdo",
                                                            fontFamily: 'Arial',
                                                            fontWeight: 'bolder',
                                                            fontSize: 23,
                                                            fontColor: 'black',
                                                            borderColor: 'black',
                                                        },
                                                        series: [
                                                            {
                                                                type: "pie",
                                                                angleKey: "amount",
                                                                legendItemKey: "asset",
                                                            },
                                                        ],
                                                    }} />
                                                </Div>
                                            )
                                        } else if(item==='atividades') {
                                            return (
                                                <Div key={`${item}-${index}`} sx={{width: `${100/chunkSize}%`}}>
                                                    {/* <Chart
                                                        width={'100%'}
                                                        height={'400px'}
                                                        chartType="PieChart"
                                                        data={[
                                                            ['Tipo de Atividades','Quantidade'],
                                                            ...tipoAtividades.map((item) => [item.label,item.value])
                                                        ]}
                                                        options={{
                                                            title: 'Tipo de Atividades',
                                                        }}
                                                    /> */}
                                                    <AgCharts style={{height: '400px'}} options={{
                                                        data: tipoAtividades,
                                                        title: {
                                                            text: "Tipo de Atividades",
                                                            fontFamily: 'Arial',
                                                            fontWeight: 'bolder',
                                                            fontSize: 23,
                                                            fontColor: 'black',
                                                            borderColor: 'black',
                                                        },
                                                        series: [
                                                            {
                                                                type: "pie",
                                                                angleKey: "amount",
                                                                legendItemKey: "asset",
                                                            },
                                                        ],
                                                    }} />
                                                </Div>
                                            )
                                        } else if(item==='relacionamento') {
                                            return (
                                                <Div key={`${item}-${index}`} sx={{display: 'flex',flexDirection: 'column',marginTop: '15px',alignItems: "center",gap: '16px', justifyContent: 'center', width: '100%'}}>
                                                    <Typography color='black' sx={{fontSize: '23px',fontFamily: 'Arial',fontWeight: 'bold',color: '#4c4949'}}>Encontros de Relacionamentos</Typography>
                                                    <Div sx={{height: '250px',width: '250px',borderRadius: '50%',backgroundColor: '#00a29b8c',display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                                                        <Typography color='black' sx={{fontSize: '6rem',textShadow: '2px 3px 0px #fff',fontFamily: 'Arial'}}>{totalRelacionamento}</Typography>
                                                    </Div>
                                                </Div>
                                            )
                                        }
                                    })}
                            </Div>
                        ))
                        }
                    </Carousel>
                ):(
                    <>
                        <Div sx={{display: 'flex',justifyContent: 'center',flexWrap: 'wrap',padding: 2}}>
                            <Div sx={{width: width(),height: '400px'}}>
                                {/* <Chart
                                    width={width()}
                                    height={'400px'}
                                    chartType="PieChart"
                                    data={[
                                        ['Conteudo','Quantidade'],
                                        ...conteudo.map((item) => [item.label,item.value])
                                    ]}
                                    options={{
                                        title: 'Conteúdo',
                                    }}
                                /> */}
                                {/* <CanvasJSChart options={{
                                    animationEnabled: true,
                                    title: {
                                        text: "Conteúdo"
                                    },
                                    data: [
                                        {
                                            type: "pie",
                                            showInLegend: false,
                                            legendText: "{label}",
                                            dataPoints: conteudo.map((item) => ({y: item.value,label: item.label}))
                                        }
                                    ]
                                }} /> */}
                                <AgCharts style={{height: '400px'}} options={{
                                    data: conteudo,
                                    title: {
                                        text: "Conteúdo",
                                        fontFamily: 'Arial',
                                        fontWeight: 'bolder',
                                        fontSize: 23,
                                        fontColor: 'black',
                                        borderColor: 'black',
                                    },
                                    series: [
                                        {
                                            type: "pie",
                                            angleKey: "amount",
                                            legendItemKey: "asset",
                                        },
                                    ],
                                }} />
                            </Div>
                            <Div sx={{width: width(),height: '400px'}}>
                                {/* <Chart
                                    width={width()}
                                    height={'400px'}
                                    chartType="PieChart"
                                    data={[
                                        ['Tipo de Atividades','Quantidade'],
                                        ...tipoAtividades.map((item) => [item.label,item.value])
                                    ]}
                                    options={{
                                        title: 'Tipo de Atividades',
                                    }}
                                /> */}
                                {/* <CanvasJSChart options={{
                                    animationEnabled: true,
                                    title: {
                                        text: "Tipo de Atividades"
                                    },
                                    data: [
                                        {
                                            type: "pie",
                                            showInLegend: false,
                                            legendText: "{label}",
                                            dataPoints: tipoAtividades.map((item) => ({y: item.value,label: item.label}))
                                        }
                                    ]
                                }} /> */}
                                <AgCharts style={{height: '400px'}} options={{
                                    data: tipoAtividades,
                                    title: {
                                        text: "Tipo de Atividades",
                                        fontFamily: 'Arial',
                                        fontWeight: 'bolder',
                                        fontSize: 23,
                                        fontColor: 'black',
                                        borderColor: 'black',
                                    },
                                    series: [
                                        {
                                            type: "pie",
                                            angleKey: "amount",
                                            legendItemKey: "asset",
                                        },
                                    ],
                                }} />
                            </Div>
                            <Div sx={{display: 'flex',flexDirection: 'column',marginTop: '15px',alignItems: "center",gap: '16px'}}>
                                <Typography color='black' sx={{fontSize: '23px',fontFamily: 'Arial',fontWeight: 'bold',color: '#4c4949'}}>Encontros de Relacionamentos</Typography>
                                <Div sx={{height: '250px',width: '250px',borderRadius: '50%',backgroundColor: '#00a29b8c',display: 'flex',justifyContent: 'center',alignItems: 'center'}}>
                                    <Typography color='black' sx={{fontSize: '6rem',textShadow: '2px 3px 0px #fff',fontFamily: 'Arial'}}>{totalRelacionamento}</Typography>
                                </Div>
                            </Div>
                        </Div>
                    </>
                )}
            <Div sx={{width: '100%',display: 'flex',flexDirection: 'column',gap: 3}}>
                <Div>
                    <Button variant='outlined' startIcon={<IconFilter />} sx={{color: 'white !important'}} onClick={handleOpenFilterModal}>Filtros</Button>
                </Div>
                <Div>
                    {filterModalOpen&&filterModal()}
                </Div>
                <Box sx={{display: 'flex',alignItems: 'center',justifyContent: 'space-between'}}>
                    <TablePagination
                        rowsPerPageOptions={limits}
                        component="div"
                        labelRowsPerPage={'Exibir'}
                        labelDisplayedRows={() => ''}
                        count={totalItems}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        nextIconButtonProps={{style: {display: "none"}}}
                        backIconButtonProps={{style: {display: "none"}}}
                        sx={{
                            '& .MuiSelect-select': {
                                color: `black !important`
                            },
                            '& .MuiTablePagination-selectLabel': {
                                color: 'black !important'
                            },
                            '.MuiTablePagination-menuItem': {
                                color: 'black !important'
                            },
                            'MuiList-root': {
                                color: 'black !important'
                            }
                        }}
                    />
                    <Pagination page={page} onChange={handleChangePage} count={totalPages} variant="outlined" shape="rounded" />
                </Box>
                <TableContainer sx={{maxHeight: '100%',borderRadius: 1}}>
                    <Table aria-label="customized collapsible table">
                        <TableHeadComponent columns={columns} border={true} />
                        <TableBody>
                            {atividades.map((row,index) => (
                                <TableRow key={row.titulo}>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">
                                        {row.data.split('-').reverse().join('/')}
                                    </TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">{row.tipo_pinepr_atividade}</TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">{row.tipo_pinepr_conteudo}</TableCell>
                                    <TableCell color='black' sx={{borderLeft: '1px solid #00000047!important','& > *': {borderBottom: 'unset'},backgroundColor: index%2===0? `${theme.palette.table.tableContraste} !important`:'',":hover": {'cursor': 'pointer'}}} align="center">
                                        {row.detalhes.length>60? `${row.detalhes.substring(0,60)}...`:row.detalhes}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Div>
        </Div>
    )
}

export default Atividades;