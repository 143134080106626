import React,{useEffect,useRef,useState} from "react";
import {Avatar,Box,Chip,MenuItem,Typography,useTheme} from "@mui/material";
import Div from "ui-component/layouts/components/Div";
import * as Icons from '@mui/icons-material';
import Popover from '@mui/material/Popover';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import {Gauge,gaugeClasses} from "@mui/x-charts";
import ListarModal from "./ListarModal";

export default function CardAnalytics({noAvatar=false,gauge=false,iconName,value,label,percentage,info,results,color='white',rotaBase='pinepr/clipping',where}) {
    const [anchorEl,setAnchorEl]=useState(null);
    const [openMenu,setOpenMenu]=useState(false);
    const [menuOptions,setMenuOptions]=useState([]);
    const [selectItemMenu,setSelectItemMenu]=useState(null);
    const [openResults,setOpenResults]=useState(false);
    const theme=useTheme();
    const menuRef=useRef(null);
    const hasMenu=results||info;

    function Icon({iconName}) {
        const IconComponent=Icons[iconName];
        if(!IconComponent) {
            // Ícone não encontrado, você pode renderizar um ícone padrão ou tratar de outra forma
            return null;
        }
        return (
            <IconComponent
                className="hover-icon"
                style={{
                    marginTop: 'auto',
                    marginBottom: 'auto',
                    transition: 'opacity 0.1s ease-in-out',
                }}
                sx={{
                    width: '50px',
                    height: '50px',
                    color: color,
                }}
            />
        );
    }

    useEffect(() => {
        if(results&&info) {
            setMenuOptions([
                {nome: 'Resultados',chave: 'results'},
                {nome: 'Informações',chave: 'info'}
            ]);
        } else if(results) {
            setMenuOptions([{nome: 'Resultados',chave: 'results'}]);
        } else if(info) {
            setMenuOptions([{nome: 'Informações',chave: 'info'}]);
        }
    },[results,info]);

    const handlePopoverOpen=(event) => {
        setAnchorEl(event.currentTarget);
        setSelectItemMenu(false);
    };

    const handlePopoverClose=() => {
        setAnchorEl(null);
    };

    const handleMenuItem=(opcao) => {
        setSelectItemMenu(true);
        setOpenResults(false);
        if(opcao.chave==='results') {
            setOpenResults(true);
            setSelectItemMenu(false);
            setOpenMenu(false);
        } else if(opcao.chave==='info') {
            setOpenMenu(false);
        }
    }

    useEffect(() => {
        const handleClickOutside=(event) => {
            if(menuRef.current&&!menuRef.current.contains(event.target)&&!selectItemMenu) {
                setOpenMenu(false);
            }
        };

        document.addEventListener('mousedown',handleClickOutside);
        return () => {
            document.removeEventListener('mousedown',handleClickOutside);
        };
    },[menuRef,selectItemMenu])

    const open=Boolean(anchorEl);

    const percentageValue=Number(percentage.split('%')[0]);
    let gaugeValue=0;

    if(gauge) {
        if(typeof value==='string'&&value.includes('%')) {
            gaugeValue=Number(value.split('%')[0]);
        } else {
            gaugeValue=value
        }
    }

    return (
        <>
            <Div sx={{
                position: 'relative',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                transition: 'all 0.2s ease-in-out',
                gap: 1.3,
                textAlign: 'center',
                '.css-7jghex-MuiChip-root .MuiChip-avatar ': {
                    width: '0px',
                },
                '&:hover': {
                    '& .hover-label': {
                        background: '#00c2a8',
                        color: 'black',
                        transition: 'background 0.2s ease-in-out, color 0.2s ease-in-out',
                    },
                    '& .hover-avatar': {
                        opacity: 1,
                        width: '34px !important',
                        margin: 0
                    },
                    '& .hover-value': {
                        fontSize: '36px',
                    },
                    '& .hover-info': {
                        opacity: 1,
                    }
                },
            }}
                onMouseEnter={() => setOpenMenu(false)}
                onMouseLeave={() => setOpenMenu(false)}
            >
                {
                    hasMenu&&(
                        <Avatar
                            variant="rounded"
                            className="hover-info"
                            sx={{
                                ...theme.typography.commonAvatar,
                                ...theme.typography.mediumAvatar,
                                backgroundColor: 'rgb(0, 162, 155) !important',
                                '&:hover': {
                                    backgroundColor: 'rgb(0, 162, 155) !important',
                                },
                                color: theme.palette.reso.white,
                                zIndex: 1,
                                opacity: 0,
                                transition: 'opacity 0.2s ease-in-out',
                                position: 'absolute',
                                right: '10%',
                                top: '0px',
                            }}
                            aria-controls="menu-earning-card"
                            aria-haspopup="true"
                            onClick={(e) => {setOpenMenu(true); handlePopoverOpen(e)}}
                        >
                            <MoreHorizIcon fontSize="inherit" />
                        </Avatar>
                    )
                }
                {
                    openMenu&&(<Box ref={menuRef} sx={{
                        position: 'absolute',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: '10px',
                        marginRight: '-140px',
                        marginTop: '35px',
                        backgroundColor: theme.palette.background.default,
                        zIndex: 3,
                    }}>
                        {
                            menuOptions.map((opcao) => {
                                return (
                                    <MenuItem key={opcao.chave} sx={{zIndex: 4}} onClick={() => handleMenuItem(opcao)}>
                                        {/* <Icon iconName={opcao.icone} /> */}
                                        <Box sx={{marginLeft: 1}}>
                                            {opcao.nome}
                                        </Box>
                                    </MenuItem>
                                )
                            })
                        }
                    </Box>)
                }
                <Popover
                    id="mouse-over-popover"
                    PaperProps={{
                        style: {width: '300px'},
                    }}
                    open={open&&!openMenu&&selectItemMenu}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                >
                    <Typography sx={{p: 1}}>{info}</Typography>
                </Popover>
                <ListarModal openResults={openResults} setOpenResults={setOpenResults} rotaBase={rotaBase} where={where} />
                {
                    !gauge? (
                        <>
                            {
                                iconName.includes('http')? (
                                    <img src={iconName} alt="Ícone" style={{width: '130px',height: '110px'}} />
                                ):(
                                    <Icon iconName={iconName} />
                                )
                            }
                        </>
                    ):(
                        <Gauge width={130} height={130} value={gaugeValue} sx={() => ({
                            [`& .${gaugeClasses.valueText}`]: {
                                fontSize: 30,
                                fill: 'black'
                            },
                            [`& .${gaugeClasses.referenceArc}`]: {
                                fill: "black",
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                                fill: "rgb(0, 162, 155)",
                            },
                        })} />
                    )
                }

                {
                    !gauge&&(
                        <Typography
                            variant="h2"
                            className="hover-value"
                            sx={{
                                color: color,
                                fontSize: '34px',
                                transition: 'font-size 0.2s ease-in-out',
                            }}
                        >
                            {value}
                        </Typography>
                    )
                }

                <Typography
                    variant="h2"
                    className="hover-label"
                    sx={{
                        color: color,
                        fontSize: '17px',
                        borderRadius: '1rem',
                        padding: '.4rem .6rem',
                        transition: 'background 0.2s ease-in-out, color 0.2s ease-in-out',
                    }}
                >
                    {label}
                </Typography>
                <Chip
                    avatar={noAvatar===false? percentageValue>0? <Icons.TrendingUp className="hover-avatar" sx={{width: '0px !important',opacity: 0,transition: 'opacity 0.2s ease-in-out, width 0.2s ease-in-out',background: '#23e1bb',fill: 'black',borderRadius: '16px 0px 0px 16px',margin: 0}} />:<Icons.TrendingDown className="hover-avatar" sx={{width: '0px !important',opacity: 0,transition: 'opacity 0.2s ease-in-out, width 0.2s ease-in-out',background: '#f21280',fill: 'black',borderRadius: '16px 0px 0px 16px',margin: 0}} />:null}
                    label={percentage}
                    sx={{
                        background: '#666666',
                        color: 'white',
                        fontSize: '12px',
                        fontWeight: 'bold',
                        height: '24px',
                        ml: 0
                    }}
                />
            </Div>
        </>
    );
}
