import Listar from './Listar'
import {Box,Modal} from "@mui/material";

export default function ListarModal({openResults, setOpenResults, rotaBase, where}) {
    return (
        <Modal open={openResults} onClose={() => {
            setOpenResults(false)
        }}>
            <Box sx={{
                position: 'absolute',
                overflow: 'auto',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '80%',
                p: 4,
            }}>
                <Listar visualizarListar={false} name={'Clippings'} rotaBase={rotaBase} notMenu={true} where={where} />
            </Box>
        </Modal>
    )
}