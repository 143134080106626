import {Card, CardContent, CardHeader, Typography} from "@mui/material";
import Chart from "react-apexcharts";

export default function CardGraphPie({cardSx, title = 'Sentiment', stroke = 'black', color = 'white', colors = ['#612466','#e82276','#00a29b','#b5b72a','#ff9933','#ff7f0e'], labels = ['Very Positive','Positive'], series = [1000,500]}) {
    return (
        <Card sx={{borderRadius: '0px',height: '100%',backgroundColor: stroke, ...cardSx}}>
            <CardHeader title={<Typography variant={"h2"} mb={0} sx={{fontSize: 20,color: color,letterSpacing: 1.5}}>{title}</Typography>} />
            <CardContent>
                <Chart
                    options={{
                        stroke: {
                            colors: stroke
                        },
                        colors: colors,
                        labels: labels,
                        legend: {
                            labels: {
                                colors: color,
                            }
                        },
                        plotOptions: {
                            pie: {
                                dataLabels: {
                                    offset: -10,
                                },
                            },
                        },
                    }}
                    series={series}
                    type="pie"
                    height={200}
                />
            </CardContent>
        </Card>
    )
}