import {useSelector} from 'react-redux';

import {ThemeProvider} from '@mui/material/styles';
import {CssBaseline,StyledEngineProvider} from '@mui/material';
import 'react-calendar-timeline/lib/Timeline.css'
import alerta from './assets/audio/alerta.mp3';

// routing
import Routes from 'routes';

// defaultTheme
import themes from 'themes';

// project imports
import NavigationScroll from 'layout/NavigationScroll';
import {useSocketConfig} from 'contexts/SocketConfig';
// ==============================|| APP ||============================== //

const App=() => {
  const customization=useSelector((state) => state.customization);
  const {emitAlert}=useSocketConfig();

  return (
    <StyledEngineProvider injectFirst>
        <ThemeProvider theme={themes(customization)}>
          <CssBaseline />
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
          {
            emitAlert&&(
              <div>
                <audio autoPlay>
                  <track kind="captions" />
                  <source src={alerta} type="audio/mp3" />
                </audio>
              </div>
            )
          }
        </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
